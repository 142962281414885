import React, { useState } from "react";
import {
  Paper,
  Box,
  Button,
  Modal,
  Typography,
  IconButton,
  Grid,
  FormHelperText,
  Chip,
  Avatar,
  SvgIcon,
} from "@material-ui/core";
import ReplayCircleFilledIcon from '@material-ui/icons/Refresh';

import { makeStyles } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  EMSimpleTextFieldView,
} from "../../../components/EMTextFieldView";
import { CloseRounded } from "@material-ui/icons";
import { useFormik, Field } from "formik";
import * as yup from "yup";
import ChipInput from "material-ui-chip-input";
import { useEffect } from "react";
import {
  EMDoCreateOrganizationAction,
  EMDoGetOrganizationSubTypesAction,
  EMDoResendInvites,
  EMDoUpdateOrganizationAction,
} from "../../../redux/actions/EMAdminActions";
import { useDispatch, useSelector } from "react-redux";
import EMDatepicker from "../../../components/commans/EMDatePicker";
import { findIndex, filter, chain, map, difference, isEmpty } from "lodash";
import moment from "moment";
import { DATATABLE_DATE_FORMAT } from "../../../utils/EMConstants";
import API from "../../../api";
import EMRadioGroup from "../../../components/commans/EMRadioGroup";
import EMCountryAndStateSelector from "../../../components/commans/EMCountryAndStateSelector";
import EMSingleSelect from "../../../components/commans/EMSingleSelect";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("xs")]: {
      backgroundColor: theme.palette.common.white,
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "70%",
      marginTop: "25rem",
      transform: "translate(0, -50%)",
      // marginLeft: "5rem",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("sm")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX:"hidden"
    },
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.common.white,
      height: "auto",
      minWidth: "50%",
      margin: "auto",
      padding: "0px",
      maxHeight: "100%",
      position: "fixed",
      top: "50%",
      transform: "translate(0, -50%)",
      width: "50%",
      overflowY: "auto",
      overflowX:"hidden"
    },
  },
  title: {
    margin: 10,
    flexGrow: 1,
    fontWeight: "bold",
    color:"white",
    fontSize:"large"
  },
  form: {
    padding: "0px 24px 0px 24px",
    width: "100%",
    flexGrow: 1,
    marginTop: "1%",
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 4px 0px 18px",
    backgroundColor:"#2F92D6"
  },
  formStyle: {
    minWidth: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 132,
  },
  textfield: {
    padding: "0px 24px 0px 24px",
    width: "100%",
    flexGrow: 1,
    marginTop: "1%",
  },
  space: {
    width: "35%",
    marginRight: "4%",
  },
  chip:{
    marginLeft:"10px",
    marginBottom:"10px"
  }
}));

export default function EMAddEditOrganization(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [organizationTypeData, setOrganizationTypeData] = useState([]);
  const [adminEmails, setAdminEmails] = useState([]);
  const [superAdminEmails, setSuperAdminEmails] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const platformAdminData = useSelector((state) => state.admin || {});

  const validationSchema = yup.object({
    organization_name: yup
      .string("Enter Organization Name")
      .trim()
      .max(150, "Maximum 150 characters are allowed")
      .required("Organization Name is required"),
    organization_type: yup
      .string("Select Organization Type")
      .trim()
      .required("Organization Type is required"),
    perm_address: yup
      .string("Enter Organization Address")
      .trim()
      .required("Organization Address is required"),
    perm_city: yup
      .string("Enter Organization City")
      .trim()
      .required("City is required"),
    perm_postal_code:
    yup.string()
    .required("Zip code is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    
    .max(10, 'Invalid zip code'),
    perm_country: yup.string("Select Country").required("Country is required"),
    perm_state: yup
      .string("Select Organization State")
      .required("State is required"),
    super_admin_email: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(yup.string().email(({ value }) => `${value} is not a valid email`))
      .of(yup.string().max(150, "Maximum 150 characters are allowed"))
      .min(1, "Only one super admin can be added")
      .max(1, "Only one super admin can be added")
      .required("Super admin email is required"),

    admin_emails: yup
      .array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      .of(yup.string().email(({ value }) => `${value} is not a valid email`))
      .of(yup.string().max(150, "Maximum 150 characters are allowed"))
      .min(1, "Admin Email Address Field must have atleast one email")
      .required("Admin email is required"),
    subscription_start_date: yup.date(),
    subscription_end_date: yup
      .date()
      .min(
        yup.ref("subscription_start_date"),
        "Subscription End Date Must be After Subscription Start Date"
      ),
  });

  let oldvalues;
  let admin_emails = [];

  if (props.organizationData) {
    // props.organizationData["users"] &&
    //   props.organizationData["users"].map((user) => {
    //     if (user.is_also_admin === true) {
    //       super_admin_email.push(user.email);
    //     }
    //   });
    props.organizationData["users"] &&
      filter(props.organizationData["users"], {
        role: { role_name: "Admin" },
      }).map((x) => admin_emails.push(x["email"]));
  }

  oldvalues = admin_emails

  useEffect(() => {
    setDisabled(false);
    if (props.organizationData) {
      filter(props.organizationData["users"], {
        role: { role_name: "Super Admin" },
      }).map((x) => setSuperAdminEmails(x["email"]));
      setAdminEmails(oldvalues);
    }
  }, [props.organizationData]);

  useEffect(() => {
    setDisabled(false);
    if (!isEmpty(props.organizationData)) {
      if (props.organizationData.invited_users.length > 0) {
        setInvitedUsers(
          props.organizationData.invited_users.map((user) => user.email)
        );
      } else {
        setInvitedUsers([]);
      }
    }
  }, [props.organizationData]);

  useEffect(() => {
    setDisabled(false);
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization_name: props.organizationData["organization_name"] || "",
      organization_type: props.organizationData["organization_type"]
        ? parseInt(props.organizationData["organization_type"]["id"])
        : undefined,
      super_admin_email: props.organizationData["users"]
        ? filter(props.organizationData["users"], {
            role: { role_name: "Super Admin" },
          }).map((x) => x["email"])
        : [],
      admin_emails: props.organizationData["users"] ? oldvalues : [],
      subscription_start_date: props.organizationData["subscription_start_date"]
        ? moment(props.organizationData["subscription_start_date"]).format(
            DATATABLE_DATE_FORMAT
          )
        : moment().format(DATATABLE_DATE_FORMAT),
      subscription_end_date: props.organizationData["subscription_end_date"]
        ? moment(props.organizationData["subscription_end_date"]).format(
            DATATABLE_DATE_FORMAT
          )
        : moment(
            moment().format(DATATABLE_DATE_FORMAT),
            DATATABLE_DATE_FORMAT
          ).add(1, "month"),
      //:moment(yup.ref('subscription_start_date'),DATATABLE_DATE_FORMAT).add(1, 'month'),
      perm_address: props.organizationData["perm_address"] || "",
      perm_city: props.organizationData["perm_city"] || "",
      perm_postal_code: props.organizationData["perm_postal_code"] || "",
      perm_country: props.organizationData["perm_country"] || "",
      perm_state: props.organizationData["perm_state"] || "",
      org_sub_type: props.organizationData["org_sub_type"] || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.setDisabled(true);
      setDisabled(true);
      values.subscription_start_date = moment(
        values.subscription_start_date
      ).utc();
      values.subscription_end_date = moment(values.subscription_end_date).utc();
      if (props.organizationData && props.organizationData.id > 0) {
        let deleted_admin_emails = difference(
          filter(props.organizationData["users"], {
            role: { role_name: "Admin" },
          }).map((x) => x["email"]),
          formik.values.admin_emails
        );
        let deleted_super_admin_email = difference(
          filter(props.organizationData["users"], {
            role: { role_name: "Super Admin" },
          }).map((x) => x["email"]),
          formik.values.super_admin_email
        );
        let new_admin_emails = difference(
          formik.values.admin_emails,
          filter(props.organizationData["users"], {
            role: { role_name: "Admin" },
          }).map((x) => x["email"])
        );
        let admin_emails = difference(
          formik.values.admin_emails,
          new_admin_emails
        );
        values = { ...values, admin_emails: admin_emails };
        let new_super_admin_email = difference(
          formik.values.super_admin_email,
          filter(props.organizationData["users"], {
            role: { role_name: "Super Admin" },
          }).map((x) => x["email"])
        );
        let newOrganizationData = {
          ...props.organizationData,
          ...values,
          deleted_admin_emails,
          new_admin_emails,
          deleted_super_admin_email,
          new_super_admin_email,
          //super_admin_email: values.super_admin_email[0]
        };
        dispatch(
          EMDoUpdateOrganizationAction({
            ...newOrganizationData,
            key: "organization_add_edit",
          })
        );
      } else {
        let newOrganizationData = {
          ...values,
          //super_admin_email: values.super_admin_email[0]
        };
        dispatch(
          EMDoCreateOrganizationAction({
            ...newOrganizationData,
            key: "organization_add_edit",
          })
        );
      }
    },
  });

  const getOrganizationTypeListFromServer = () => {
    API.EMDoGetOrganizationTypeList()
      .then((data) => data.data)
      .then((data) => {
        if (data) {
          setOrganizationTypeData(data);
        }
      })
      .catch((err) => {});
  };

  //reset the form after modal is closed
  useEffect(() => {
    getOrganizationTypeListFromServer();
    return () => {
      formik.resetForm();
    };
  }, [props.open]);

  useEffect(() => {
    if (props.organizationData && props.organizationData["perm_country"]) {
      if (
        props.organizationData["perm_country"] !== formik.values.perm_country
      ) {
        formik.values.perm_state = "";
      }
    }
  }, [formik.values.perm_country]);

  useEffect(() => {
    
    if(formik.values.organization_type && formik.values.organization_type == 1){
      dispatch(EMDoGetOrganizationSubTypesAction({orgType:"company"}))
    }else if(formik.values.organization_type && formik.values.organization_type == 2){
      dispatch(EMDoGetOrganizationSubTypesAction({orgType:"association"}))
    }
  },[formik.values.organization_type])

const handleResend=()=>{
  const admins={
    "invited_admins":  props.organizationData.invited_users,
    "organization_id":props.organizationData.id
}
dispatch(EMDoResendInvites(admins));
}

  return (
    <Modal
      open={props.open}
      onClose={props.close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className={classes.root}>
        <form onSubmit={formik.handleSubmit}>
          <Box className={classes.box}>
            <Typography className={classes.title}>{props.pagelabel}</Typography>
            <IconButton onClick={props.close} >
              <CloseRounded style={{color:"white"}} />
            </IconButton>
          </Box>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ width: "100%" }}
            className={classes.form}
          >
            <EMSimpleTextFieldView
              label={"Organization Name"}
              id="organization_name"
              name="organization_name"
              value={formik.values.organization_name}
              onChange={formik.handleChange}
              // error={
              //   formik.touched.organization_name &&
              //   Boolean(formik.errors.organization_name)
              // }
              helperText={
                formik.touched.organization_name &&
                formik.errors.organization_name
              }
            />
          </Grid>
          <Grid
            className={classes.form}
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{paddingTop:"16px"}}
          >
            <Grid item xs={12} sm={8} md={6}>
              <EMRadioGroup
                label={"Organization type"}
                value={parseInt(formik.values.organization_type)}
                data={organizationTypeData}
                labelKey={"description"}
                onChange={(event) => {
                  formik.setFieldValue("organization_type", event.target.value);
                }}
                formik={{
                  errors: formik.errors.organization_type,
                  touched: formik.touched.organization_type,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={6}>
              {formik.values.organization_type ? (
                <EMSingleSelect
                  required={false}
                  disabled={false}
                  label={"Select Organization Sub Type"}
                  objKey={"sub_type"}
                  data={platformAdminData.organizationSubTypes || []}
                  onChange={(selectedOrgSubtype) => {
                    let orgSubType = selectedOrgSubtype;
                    formik.setFieldValue("org_sub_type", orgSubType);
                  }}
                  value={formik.values.org_sub_type}
                  // error={Boolean(
                  //   formikForUserData.touched.department &&
                  //     formikForUserData.errors.department
                  // )}
                  // helperText={
                  //   formikForUserData.touched.department &&
                  //   formikForUserData.errors.department
                  // }
                />
              ) : null} 
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            style={{ width: "100%" }}
            className={classes.form}
          >
            <EMSimpleTextFieldView
              label={"Organization Address"}
              id="perm_address"
              name="perm_address"
              value={formik.values.perm_address}
              onChange={formik.handleChange}
              // error={
              //   formik.touched.perm_address &&
              //   Boolean(formik.errors.perm_address)
              // }
              helperText={
                formik.touched.perm_address && formik.errors.perm_address
              }
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            className={(classes.space, classes.textfield)}
            spacing={0}
          >
            <Grid tem xs={12} sm={2} md={2} className={classes.space}>
              <EMSimpleTextFieldView
                label={"City"}
                id="perm_city"
                name="perm_city"
                value={formik.values.perm_city}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.perm_city && Boolean(formik.errors.perm_city)
                // }
                helperText={formik.touched.perm_city && formik.errors.perm_city}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.space}>
              <EMCountryAndStateSelector
              required={true}
                idCountry="perm_country"
                idState="perm_state"
                showOnlyCountry={false}
                handleChangeCountry={formik.handleChange}
                handleChangeState={formik.handleChange}
                valueCountry={formik.values.perm_country}
                valueState={formik.values.perm_state}
                errorCountry={
                  formik.touched.perm_country &&
                  Boolean(formik.errors.perm_country)
                }
                helperTextCountry={
                  formik.touched.perm_country && formik.errors.perm_country
                }
                errorState={
                  formik.touched.perm_state && Boolean(formik.errors.perm_state)
                }
                helperTextState={
                  formik.touched.perm_state && formik.errors.perm_state
                }
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} className={classes.space}>
              <EMSimpleTextFieldView
                label={"Zip Code"}
                id="perm_postal_code"
                name="perm_postal_code"
                value={formik.values.perm_postal_code}
                onChange={formik.handleChange}
                // error={
                //   formik.touched.perm_postal_code &&
                //   Boolean(formik.errors.perm_postal_code)
                // }
                helperText={
                  formik.touched.perm_postal_code &&
                  formik.errors.perm_postal_code
                }
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.form}
            style={{ marginBottom: "3%" }}
          >
            <ChipInput
              name="super_admin_email"
              InputLabelProps={{style:{color:"#4d4d4d"}}}
              id="super_admin_email"
              type="email"
              label="Superadmin E-mail Address"
              FormHelperTextProps={{style:{color:"red"}}}
              helperText={
                formik.errors.super_admin_email &&
                formik.touched.super_admin_email
                  ? formik.errors.super_admin_email
                  : ""
              }
              chipRenderer={({ value, className }, key) => (
                <Chip
                  key={key}
                  label={value}
                  className={classes.chip}
                  deleteIcon={<CancelIcon />}
                  onDelete={
                    superAdminEmails.length > 0 &&
                    superAdminEmails.includes(value)
                      ? undefined
                      : () => {
                          let oldValues = [...formik.values.super_admin_email];
                          let index = key;
                          if (index > -1) {
                            oldValues.splice(index, 1);
                            formik.setFieldValue(
                              "super_admin_email",
                              oldValues
                            );
                          }
                        }
                  }
                ></Chip>
              )}
              value={formik.values.super_admin_email}
              // error={
              //   formik.errors.super_admin_email &&
              //   formik.touched.super_admin_email
              //     ? true
              //     : false
              // }
             
              onAdd={(chip) => {
                if (chip) {
                  let oldvalues = [...formik.values.super_admin_email];
                  let index = findIndex(oldvalues, chip);
                  if (index === -1) {
                    oldvalues.push(chip.toLowerCase());
                    formik.setFieldValue("super_admin_email", oldvalues);
                  }
                }
              }}
              fullWidth
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            className={classes.form}
            style={{ marginBottom: "3%" }}
          >
            <ChipInput
              name="admin_emails"
              InputLabelProps={{style:{color:"#4d4d4d"}}}
              id="admin_emails"
              type="email"
              label="Admin E-mail Address"
              FormHelperTextProps={{style:{color:"red"}}}
              helperText={
                formik.errors.admin_emails && formik.touched.admin_emails
                  ? formik.errors.admin_emails
                  : ""
              }
              value={formik.values.admin_emails}
              chipRenderer={({ value, className }, key) => (
                <Chip
                  key={key}
                  label={value}
                  className={classes.chip}
                  deleteIcon={<CancelIcon />}
                  onDelete={
                    adminEmails.length > 0 && adminEmails.includes(value)
                      ? undefined
                      : () => {
                          let oldValues = [...formik.values.admin_emails];
                          let index = key;
                          if (index > -1) {
                            oldValues.splice(index, 1);
                            formik.setFieldValue("admin_emails", oldValues);
                          }
                        }
                  }
                ></Chip>
              )}
              // error={
              //   formik.errors.admin_emails && formik.touched.admin_emails
              //     ? true
              //     : false
              // }
              onAdd={(chip) => {
                if (chip) {
                  let oldvalues = [...formik.values.admin_emails];
                  let index = findIndex(oldvalues, chip);
                  if (index === -1) {
                    oldvalues.push(chip.toLowerCase());
                    formik.setFieldValue("admin_emails", oldvalues);
                  }
                }
              }}
              fullWidth
            />
          </Grid>
          {props.pagelabel === "Edit Organization Details" &&
          props.organizationData.invited_users ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              className={classes.form}
              style={{ marginBottom: "3%" }}
            >
              <ChipInput
                name="invited_users"
                id="invited_users"
                type="email"
                label="Invited Admin's Emails"
                chipRenderer={({ value }, key) => (
                  <Chip
                    key={key}
                    label={value}
                    // onDelete={handleResend}
                    // deleteIcon={<ReplayCircleFilledIcon/>}
          
                  />
                )}
                value={invitedUsers}
                margin="none"
                fullWidth
              />
            </Grid>
          ) : null}

          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignContent="center"
            className={classes.form}
          >
            <Grid item xs={12} sm={12} md={6}>
              <EMDatepicker
                label={"Subscription Start Date"}
                id={"subscription_start_date"}
                value={formik.values.subscription_start_date}
                onSelectDate={(date) => {
                  formik.setFieldValue("subscription_start_date", date);
                }}
              />
              {formik.errors.subscription_start_date && (
                <FormHelperText>
                  {formik.errors.subscription_start_date}
                </FormHelperText>
              )}
            </Grid>
            <Grid xs={12} sm={12} md={6}>
              <EMDatepicker
                label={"Subscription End Date"}
                id={"subscription_end_date"}
                value={formik.values.subscription_end_date}
                minDate={formik.values.subscription_start_date}
                onSelectDate={(date) => {
                  formik.setFieldValue("subscription_end_date", date);
                }}
                error={
                  formik.touched.subscription_end_date &&
                  Boolean(formik.errors.subscription_end_date)
                }
                helperText={
                  formik.touched.subscription_end_date &&
                  formik.errors.subscription_end_date
                }
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="row"
            className={classes.buttonHolder}
            spacing={4}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                type="submit"
                // disabled={disabled}
              >
                <Typography variant="h7">{props.buttonLabel}</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="secondary"
                onClick={props.close}
              >
                <Typography variant="h7">Discard</Typography>
              </Button>
            </Grid>
 {     props.pagelabel === "Edit Organization Details" &&
          props.organizationData.invited_users?.length > 0 &&    <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                onClick={handleResend}
              >
                <Typography variant="h7">Resend invites</Typography>
              </Button>
            </Grid>}
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
