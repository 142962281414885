import axios from "axios";

//
const EMDoGetChannelPreviewList = () => {
  let url = "";
  url = `/posts/list-channel/`;
  return axios.get(url);
};

//GET request - posts list
const EMDoGetPostsList = (page) => {
  let url = `/posts/?page=${page}`;
  return axios.get(url);
};

//GET request - posts list
const EMDoGetPinnedPostsList = (page) => {
  let url = `/posts/pinned-posts/?page=${page}`;
  return axios.get(url);
};

const EMDoLikeDislikePinUnpinPost = ({ queryData }) => {
  let url = "";
  if (queryData) {
    url = `/posts/${queryData.id}/action:${queryData.action}/`;
  } else {
    url = ``;
  }
  return axios.patch(url);
};

// GET request - category filtered post list
const EMDoGetCategoryFilteredPostList = (selectedOption, page) => {
  let url;
  if (selectedOption) {
    url = `/posts/?category=${selectedOption}&page=${page}`;
  } else {
    url = `/posts/?`;
  }

  return axios.get(url);
};

// GET request - category filtered pinned post list
const EMDoGetCategoryFilteredPinnedPostList = (selectedOption, page) => {
  let url;

  if (selectedOption) {
    url = `/posts/pinned-posts/?category=${selectedOption}&page=${page}`;
  } else {
    url = `/posts/pinned-posts/`;
  }
  return axios.get(url);
};

//Create new hashtag

const EMDoCreateNewHashTag = (action) => {
  let url;
  if (action) {
    url = `/posts/hashtags/`;
  }
  return axios.post(url, { new_hashtag: action });
};

//GET request - shared with group list for posts
const EMDoGetPostSharedWithGroupsList = (action) => {
  let url = `/posts/channels/filter:create-post/`;
  return axios.get(url);
};

//GET request - shared with group list for polls
const EMDoGetPollSharedWithGroupsList = (action) => {
  let url = `/posts/channels/filter:create-poll/`;
  return axios.get(url);
};

//GET request - shared with group list for events
const EMDoGetEventSharedWithGroupsList = (action) => {
  let url = `/posts/channels/filter:create-event/`;
  return axios.get(url);
};

//GET request - shared with group list for quizzes
const EMDoGetQuizSharedWithGroupsList = (action) => {
  let url = `/posts/channels/filter:create-quiz/`;
  return axios.get(url);
};

//POST request - create post api
const EMDoCreateNewPost = (finalObject) => {
  let url = `/posts/`;
  
  console.log("my final object is", finalObject)

  const fileData = new FormData();
  fileData.append("title", finalObject.title);
  fileData.append("description", finalObject.description);

  finalObject.images.map((img) => {
    fileData.append("images", img);
  });
  // fileData.append("images-", finalObject.images)
  finalObject.videos.map((vid) => {
    fileData.append("videos", vid);
  });
  // fileData.append("videos", finalObject.videos)
  finalObject.documents.map((doc) => {
    fileData.append("documents", doc);
  });
  // fileData.append("documents", finalObject.documents)
  fileData.append("video_links", [finalObject.video_links]);
  fileData.append("channel_ids", [finalObject.channel_ids]);
  fileData.append("thumbnail_link", finalObject.thumbnail_link);
  fileData.append("entity", "post");

  console.log("my data to payload is ", fileData)
  return axios.post(url, fileData, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const EMDoCreateNewPoll = (finalObject) => {
  let url = `/posts/create-poll/`;
  return axios.post(url, finalObject);
};

const EMDoCreateNewOpenPoll = (finalObject) => {
  let url = `/posts/create-open-ended-question/`;
  return axios.post(url, finalObject);
};

const EMDoEditOpenPoll = (finalObject, Id) => {
  let url = `/posts/edit-open-ended-question/${Id}/`;
  return axios.put(url, finalObject, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const EMDoSubmitOpenPoll = (finalObject) => {
  let url = `/posts/submit-open-ended-question/`;
  return axios.post(url, finalObject);
};

const EMDoSubmitPoll = (finalObject) => {
  let url = `/posts/submit-poll/`;
  return axios.post(url, finalObject);
};

const EMDoSubmitQuiz = (finalObject) => {
  let url = `/posts/submit-quiz/`;
  return axios.post(url, finalObject);
};

const EMDoCreateNewEvent = (finalObject) => {
  let url = `/posts/`;

  const fileData = new FormData();
  fileData.append("title", finalObject.title);
  fileData.append("description", finalObject.description);
  
  if (finalObject.files.hasOwnProperty("icon")) {
    fileData.append("banner_file", finalObject.files.icon);
  }
  function ChnageTimeToUtc(startDate, startTime) {
    let stringFormatOfDate = startDate.toDateString();
    let stringFormatOfTimeZone = startDate.toString().slice(25, 33);
    let stringFormatOfTime = startTime;
    let modifiedDate =
      stringFormatOfDate +
      " " +
      stringFormatOfTime +
      " " +
      stringFormatOfTimeZone;

    return new Date(modifiedDate).toISOString();
  }
  fileData.append(
    "event_start_date",
    ChnageTimeToUtc(finalObject.start_date, finalObject.start_time)
  );
  fileData.append(
    "event_end_date",
    ChnageTimeToUtc(finalObject.end_date, finalObject.end_time)
  );
  fileData.append("is_online", finalObject.isOnline);
  fileData.append("is_event", finalObject.isEvent);
  fileData.append("privacy_type", 1);
  // fileData.append("video_links", [finalObject.video_links])
  fileData.append("location", finalObject.location);
  fileData.append("registration_link", finalObject.registration_link);
  fileData.append("attending_cost", finalObject.attending_cost);
  fileData.append(
    "video_links",
    finalObject.video_links.length > 0 ? finalObject.video_links : []
  );
  fileData.append("thumbnail_link", finalObject.thumbnail_link);
  fileData.append("entity", "event");
  finalObject.images.map((img) => {
    fileData.append("images", img);
  });
  // fileData.append("images-", finalObject.images)
  finalObject.videos.map((vid) => {
    fileData.append("videos", vid);
  });
  // fileData.append("videos", finalObject.videos)
  finalObject.documents.map((doc) => {
    fileData.append("documents", doc);
  });
  // if (finalObject.video_links.length > 0) {
  //   finalObject.video_links.map(link => {
  //     fileData.append("video_links", link)
  //   })
  // } else {
  //   fileData.append("video_links", finalObject.video_links)
  // }
  // fileData.append("documents", finalObject.documents)
  fileData.append("channel_ids", [finalObject.channel_ids]);
  return axios.post(url, fileData, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const EMDoEditEvent = (finalObject) => {
  let url = `/posts/${finalObject.id}/`;
  const fileData = new FormData();
  fileData.append("title", finalObject.title);
  fileData.append("description", finalObject.description);
  if (finalObject.files.hasOwnProperty("icon")) {
    fileData.append("banner_file", finalObject.files.icon);
  }
  function ChnageTimeToUtc(startDate, startTime) {
    let stringFormatOfDate = startDate.toDateString();
    let stringFormatOfTimeZone = startDate.toString().slice(25, 33);
    let stringFormatOfTime = startTime;
    let modifiedDate =
      stringFormatOfDate +
      " " +
      stringFormatOfTime +
      " " +
      stringFormatOfTimeZone;

    return new Date(modifiedDate).toISOString();
  }
  fileData.append(
    "event_start_date",
    ChnageTimeToUtc(finalObject.start_date, finalObject.start_time)
  );
  fileData.append(
    "event_end_date",
    ChnageTimeToUtc(finalObject.end_date, finalObject.end_time)
  );
  fileData.append("is_online", finalObject.isOnline);
  fileData.append("is_event", finalObject.isEvent);
  fileData.append("privacy_type", 1);
  fileData.append("location", finalObject.location);
  fileData.append("registration_link", finalObject.registration_link);
  fileData.append("attending_cost", finalObject.attending_cost);
  fileData.append(
    "images",
    finalObject.images.length > 0 ? finalObject.images : []
  );
  fileData.append(
    "videos",
    finalObject.videos.length > 0 ? finalObject.videos : []
  );
  fileData.append(
    "video_links",
    finalObject.video_links.length > 0 ? finalObject.video_links : []
  );
  fileData.append(
    "documents",
    finalObject.documents.length > 0 ? finalObject.documents : []
  );
  fileData.append("thumbnail_link", finalObject.thumbnail_link);
  fileData.append("entity", "event");
  if (finalObject.new_images.length > 0) {
    finalObject.new_images.map((img) => {
      fileData.append("new_images", img);
    });
  } else {
    fileData.append("new_images", finalObject.new_images);
  }

  if (finalObject.new_videos.length > 0) {
    finalObject.new_videos.map((vid) => {
      fileData.append("new_videos", vid);
    });
  } else {
    fileData.append("new_videos", finalObject.new_videos);
  }
  if (finalObject.new_documents.length > 0) {
    finalObject.new_documents.map((doc) => {
      fileData.append("new_documents", doc);
    });
  } else {
    fileData.append("new_documents", finalObject.new_documents);
  }
  if (finalObject.new_video_links.length > 0) {
    finalObject.new_video_links.map((link) => {
      fileData.append("new_video_links", link);
    });
  } else {
    fileData.append("new_video_links", finalObject.new_video_links);
  }
  // fileData.append("documents", finalObject.documents)
  fileData.append("channel_ids", [finalObject.channel_ids]);
  return axios.put(url, fileData, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const EMDoGetHashTagLists = () => {
  let url = `/posts/hashtags/`;
  return axios.get(url);
};
const EMDoGetPeopleMentionForPostList = (action) => {
  let url = `/posts/${action.postId}/user-mentions/`;
  return axios.get(url);
};

const EMDoGetPeopleMentionForCreatePostList = (action) => {
  let url = `/posts/user-mentions/`;
  return axios.get(url);
};

const EMDoGetCategoryList = () => {
  let url = `/commans/post-categories-list/`;
  return axios.get(url);
};
const EMDoSubmitCommentOnPost = (data) => {
  let url = `/posts/${data.post_id}/comments/`;
  return axios.post(url, data);
};

//delete post
const EMDoDeletePost = ({ queryData }) => {
  let url = "";
  if (queryData) {
    url = `/posts/${queryData.id}/action:${queryData.action}/`;
  } else {
    url = ``;
  }
  return axios.patch(url);
};

const EMDoDeletePoll = ({ queryData }) => {
  let url = "";
  if (queryData) {
    url = `/posts/delete-poll/${queryData.id}/`;
  }
  return axios.post(url);
};

const EMDoDeleteOpenPoll = ({ queryData }) => {
  let url = "";
  if (queryData) {
    url = `/posts/delete-open-ended-question/${queryData.id}/`;
  }
  return axios.post(url);
};

const EMDoEditPoll = (finalObject, Id) => {
  let url = `/posts/edit-poll/${Id}/`;
  return axios.put(url, finalObject, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const EMDoEditQuiz = (finalObject, Id) => {
  
  let url = `/posts/edit-quiz/${Id}/`;
  return axios.put(url, finalObject, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

//Edit Post Api
const EMDoEditPost = (finalObject) => {
  let url = `/posts/${finalObject.id}/`;

  const fileData = new FormData();
  fileData.append("title", finalObject.title);
  fileData.append(
    "images",
    finalObject.images.length > 0 ? finalObject.images : []
  );
  fileData.append(
    "videos",
    finalObject.videos.length > 0 ? finalObject.videos : []
  );
  fileData.append(
    "video_links",
    finalObject.video_links.length > 0 ? finalObject.video_links : []
  );
  fileData.append(
    "documents",
    finalObject.documents.length > 0 ? finalObject.documents : []
  );
  fileData.append("description", finalObject.description);
  fileData.append("channel_ids", [finalObject.channel_ids]);
  fileData.append("entity", "post");
  //New Files
  if (finalObject.new_images.length > 0) {
    finalObject.new_images.map((img) => {
      fileData.append("new_images", img);
    });
  } else {
    fileData.append("new_images", finalObject.new_images);
  }

  if (finalObject.new_video_links.length > 0) {
    finalObject.new_video_links.map((link) => {
      fileData.append("new_video_links", link);
    });
  } else {
    fileData.append("new_video_links", finalObject.new_video_links);
  }

  if (finalObject.new_videos.length > 0) {
    finalObject.new_videos.map((vid) => {
      fileData.append("new_videos", vid);
    });
  } else {
    fileData.append("new_videos", finalObject.new_videos);
  }
  if (finalObject.new_documents.length > 0) {
    finalObject.new_documents.map((doc) => {
      fileData.append("new_documents", doc);
    });
  } else {
    fileData.append("new_documents", finalObject.new_documents);
  }
  fileData.append("thumbnail_link", finalObject.thumbnail_link);
  return axios.put(url, fileData, {
    body: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const EMDoMarkHighlightedChannelAsSeen = (data) => {
  let url = `posts/mute-chanel-highlight/${data}/`;
  return axios.put(url);
};

//delete comment from post
const EMDoDeleteCommentFromPost = (data) => {
  let url = `posts/${data.postId}/comments/${data.commentId}/`;
  return axios.delete(url);
};

const EMDoMentionPeopleInPost = (data) => {
  let url = `posts/user-mentions/post/`;
  return axios.post(url, data);
};

const EMDoMentionPeopleInComment = (data) => {
  let url = `posts/user-mentions/comment/`;
  return axios.post(url, data);
};

const EMDoGetUserPoints = () => {
  let url = `gamification/user-points/`;
  return axios.get(url);
};

const EMDoGetLeaderBoard = (organizationId, page) => {
  let url = `gamification/leader-board/${organizationId}/?page=${page}`;
  return axios.get(url);
};

const EMDoCreateQuiz = (finalObject) => {

  let url = `/posts/create-quiz/`;
  return axios.post(url, finalObject);
};

const EMDoDeleteQuiz = ({queryData}) => {
  let url = "";
  if (queryData) {
    url = `/posts/delete-quiz/${queryData.id}/`;
  } else {
    url = ``;
  }
  return axios.delete(url);
};

export default {
  EMDoGetChannelPreviewList,
  EMDoGetPostsList,
  EMDoLikeDislikePinUnpinPost,
  EMDoGetCategoryFilteredPostList,
  EMDoGetPostSharedWithGroupsList,
  EMDoCreateNewPost,
  EMDoGetHashTagLists,
  EMDoGetPeopleMentionForPostList,
  EMDoGetCategoryList,
  EMDoGetPinnedPostsList,
  EMDoGetCategoryFilteredPinnedPostList,
  EMDoSubmitCommentOnPost,
  EMDoDeletePost,
  EMDoEditPost,
  EMDoDeleteCommentFromPost,
  EMDoCreateNewEvent,
  EMDoEditEvent,
  EMDoCreateNewHashTag,
  EMDoGetPeopleMentionForCreatePostList,
  EMDoMarkHighlightedChannelAsSeen,
  EMDoMentionPeopleInPost,
  EMDoMentionPeopleInComment,
  EMDoCreateNewPoll,
  EMDoSubmitPoll,
  EMDoDeletePoll,
  EMDoEditPoll,
  EMDoCreateNewOpenPoll,
  EMDoEditOpenPoll,
  EMDoSubmitOpenPoll,
  EMDoDeleteOpenPoll,
  EMDoGetPollSharedWithGroupsList,
  EMDoGetEventSharedWithGroupsList,
  EMDoGetUserPoints,
  EMDoGetLeaderBoard,
  EMDoCreateQuiz,
  EMDoDeleteQuiz,
  EMDoGetQuizSharedWithGroupsList,
  EMDoEditQuiz,
  EMDoSubmitQuiz
};
