import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useFormik, Field } from "formik";
import {
  CheckBox,
  CloseRounded,
  Visibility,
  VisibilityOff,
} from "@material-ui/icons";
import moment from "moment";
import {
  Box,
  Button,
  Grid,
  Typography,
  makeStyles,
  FormHelperText,
  IconButton,
  TextField,
  Tooltip,
  Modal,
  Input,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  InputAdornment,
} from "@material-ui/core";
import EMPage from "../../components/commans/EMPage";
import {
  EMDoGetUserDataFromKeyAction,
  EMDoCreateUserAction,
  EMDoGetAllInformationAboutUserAction,
  EMDoUpdateInformationAboutUserAction,
  EMDoGetPronounsListAction,
  EMDoGetGenderListAction,
  EMDoGetRaceListAction,
  EMDoGetMinorityListAction,
  EMDoGetCountryCodeListAction,
  EMDoGetWorkTypeListAction,
  EMDoGetPhoneTypeListAction,
  EMDoGetEthnicityTypesListAction,
} from "../../redux/actions/EMCommonActions";
import { useDispatch, useSelector } from "react-redux";
import Paper from "@material-ui/core/Paper";
import BackgroundImage from "../../assets/close-up-man-signing-contract.jpg";
import ZequalityLogo from "../../assets/zequality_logo_new.png";
import { EMSimpleTextFieldView } from "../../components/EMTextFieldView";
import Avatar from "@material-ui/core/Avatar";
import EMDatepicker from "../../components/commans/EMDatePicker";
import EMCountryAndStateSelector from "../../components/commans/EMCountryAndStateSelector";
import { find, findLast, max } from "lodash";
import { PROFILE_EDIT_TOGGLE } from "../../redux/constants/UserPlatform/EMPeopleConstant";
import EMMultipleSelect from "../../components/commans/EMMultipleSelect";
import EMImageCropper from "../../components/commans/EMImageCropper";
import EMSingleSelect from "../../components/commans/EMSingleSelect";
import EMMultiSelect from "../../components/commans/EMMultiSelect";
import EMGroupedSingleSelect from "../../components/commans/EMGroupedSingleSelect";

// import moment from 'moment';
// import Avatar from '@material-ui/core/Avatar';
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    padding: "0px",
    maxWidth: "100vw",
    // overflowX: 'auto',
    // height: "auto",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100%",
    width: "100%",
    resize: "center",
  },
  paper: {
    margin: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    //overflowX: 'auto',
    //height: "auto",
  },
  form: {
    width: "100%",
    justify: "center",
    // alignItems: "center",
    direction: "row", // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: "70%",
  },
  button: {
    margin: 0,
    position: "absolute",
  },
  root2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingBottom: 18,
  },
  root3: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  formControl: {
    minWidth: 60,
  },
  scroll: {
    // margin: "auto",
    padding: "0px",
    maxWidth: "100%",

    [theme.breakpoints.up("md")]: {
      maxHeight: "100%",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "90%",
    },
    overflowY: "auto",
    overflowX: "hidden",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  buttonHolder: {
    padding: theme.spacing(3),
  },
  submitGrid: {
    display: "flex",
    justifyContent: "center",
    margin: "15%",
    marginTop: "5%",
  },
  AvatarSize: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    backgroundColor: theme.palette.primary.main,
  },
  header: {
    fontWeight: "bold",
    marginBottom: "2%",
  },
  headerPadding: {
    padding: theme.spacing(2.5),
  },
  editPost: {
    // display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // width: "80%",
    // marginLeft: "10%",
  },
}));

export default function EMSignupPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const phoneRegExp =
    /^([\+,-,()]?)|\d{6}([- ]*)\d{9}|((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const [changePage, setChangePage] = useState(true);
  const [userData, setUserData] = useState({});
  const { key } = useParams();
  const linkedin_regex = /^https:\/\/[a-z]{2,3}\.linkedin\.com\/.*$/;
  const [profileFile, setProfileFile] = useState();
  const [profileUrl, setProfileUrl] = useState();
  const loginUserInfo = useSelector((state) => state.common.loginUserInfo);
  const authData = useSelector((state) => state.auth || {});
  const urlData = useSelector((state) => state.common.userData);
  const [data, setData] = useState();
  const commonData = useSelector((state) => state.common || {});
  const uiData = useSelector((state) => state.ui);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  let onButtonLable = props.editMode ? "Update" : "Next";

  useEffect(() => {
    if (props.editMode) {
      dispatch(
        EMDoGetAllInformationAboutUserAction({ id: authData.profile_id })
      );
    } else {
      let query = "";
      if (key) {
        query = `?key=${key}`;
      }
      dispatch(EMDoGetUserDataFromKeyAction(query));
    }
  }, [key, props.editMode]);

  useEffect(() => {
    dispatch(EMDoGetPronounsListAction());
    dispatch(EMDoGetGenderListAction());
    dispatch(EMDoGetRaceListAction());
    dispatch(EMDoGetMinorityListAction());
    dispatch(EMDoGetCountryCodeListAction());
    dispatch(EMDoGetWorkTypeListAction());
    dispatch(EMDoGetPhoneTypeListAction());
    dispatch(EMDoGetEthnicityTypesListAction());
  }, []);

  useEffect(() => {
    if (props.editMode) {
      setData(loginUserInfo);
    } else {
      setData(urlData && urlData["data"]);
    }
  }, [loginUserInfo, urlData]);

  useEffect(() => {
    if (uiData["messages"]) {
      let refObj = findLast(uiData["messages"], { key: "user_create_success" });
      if (refObj && refObj.type === "success") {
        navigate("/login");
        refObj.key = "user_create_successful";
      }
    }
  }, [uiData]);

  /* profilePic File Upload */
  const profilePicFileChangeHandler = (file) => {
    setProfileFile(file);

    if (file) {
      let url = URL.createObjectURL(file);
      setProfileUrl(url);
    }
  };

  let validationSchema = Yup.object({
    first_name: Yup.string("Enter first name")
      .trim()
      .max(255)
      .required("first name is required"),
    last_name: Yup.string("Enter Last name")
      .trim()
      .max(255)
      .required("Last name is required"),
    professional_title: Yup.string("Enter your Role")
      .trim()
      .max(255)
      .required("Your Role is Required"),
    organization: Yup.string("Enter Organization name")
      .trim()
      .max(255)
      .required("Organization name is required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .max(15)
      .min(5),
    linkedin_profile_url: Yup.string().matches(
      linkedin_regex,
      'Enter Valid Linkedin profile URL(link should start with "https://")'
    ),
    email: Yup.string()
      .email("Must be a valid email")
      .trim()
      .max(255)
      .required("Email is required"),
    // address: Yup.string("Enter House Address")
    //   .trim()
    //   .max(255)
    //   .required("House address is required"),
    city: Yup.string("Enter organization City")
      .max(255)
      // .required("City is required")
      ,
    country: Yup.string("Enter organization Country")
      .max(255)
      // .required("Country is required")
      ,
    zip_code: Yup.string()
      // .required("Zip code is required")
      .matches(/^[0-9]+$/, "Must be only digits")
 
      .max(10,"Invalid zip code"),
    state: Yup.string("Select organization Region")
      .trim()
      .max(255)
      // .required("State is required")
      ,
    about_me: Yup.string("Maximum Limit").trim().max(2600),
    date_of_birth: Yup.date("Enter Valid Date")
      .max(new Date(), "You can't select future date!")
      .nullable()
      .typeError("Invalid Date"),
    other_minority_identification: Yup.array()
      .transform(function (value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split(/[\s,]+/) : [];
      })
      // .required("Please Select Minority Identifications")
      .min(1, "Select Atleast 1 Minority Identifications"),
  });

  const [previousPronouns, setPreviousPronouns] = useState([]);
  console.log(previousPronouns)
  const [previousRace, setPreviousRace] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [previousPronounsString, setPreviousPronounsString] = useState("");
  const [previousRaceString, setPreviousRaceString] = useState("");
  const [acceptTermsAndConditionsError, setAcceptTermsAndConditionError] =
    React.useState({ state: false, errorMessage: "" });
  const [acceptTermsAndConditions, setAcceptTermsAndCondition] =
    React.useState(false);

  const [previousMinorityIdentifications, setPreviousMinorityIdentifications] =
    useState([]);
  const [
    previousMinorityIdentificationsString,
    setPreviousMinorityIdentificationsString,
  ] = useState("");
  const [organizationName, setOrganizationName] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    if (data) {
      if (props.editMode) {
        setOrganizationName(data.organization_name);
      } else {
        data.organization_type == "Company"
          ? setOrganizationName(data.organization)
          : setOrganizationName("");
      }
    }
  }, [data]);

  useEffect(() => {
    if (props.editMode && data) {
      setPreviousPronouns(
        data &&
          data.pronouns &&
          data.pronouns.length > 0 &&
          data.pronouns.map((pro, idx) => pro[idx + 1].trim())
      );
      setPreviousPronounsString(
        data &&
          data.pronouns &&
          data.pronouns.length > 0 &&
          data.pronouns.map((pro, idx) => pro[idx + 1].trim()).join(", ")
      );
      setPreviousRace(
        data &&
          data.race &&
          data.race.length > 0 &&
          data.race.map((pro, idx) => pro[idx + 1].trim())
      );
      setPreviousRaceString(
        data &&
          data.race &&
          data.race.length > 0 &&
          data.race.map((pro, idx) => pro[idx + 1].trim()).join(", ")
      );
      setPreviousMinorityIdentifications(
        data &&
          data.other_minority_identification &&
          data.other_minority_identification.length > 0 &&
          data.other_minority_identification.map((pro, idx) =>
            pro[idx + 1].trim()
          )
      );
      setPreviousMinorityIdentificationsString(
        data &&
          data.other_minority_identification &&
          data.other_minority_identification.length > 0 &&
          data.other_minority_identification
            .map((pro, idx) => pro[idx + 1].trim())
            .join(", ")
      );
      let index = find(commonData.country_codes, {
        code: data.phone_country_code,
      });
      let showValue = index !== undefined && index.name + " |" + index.code;
      if (index !== undefined && index !== false) {
        setCountryCode(showValue);
      }
    } else {
      setPreviousPronouns([]);
      setPreviousRace([]);
    }
  }, [data]);

  const formikForUserData = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: data && data.first_name ? data.first_name : "",
      last_name: data && data.last_name ? data.last_name : "",
      professional_title: (data && data.professional_title) || "",
      pronouns: props.editMode ? previousPronounsString : "",
      organization: data && data.organization ? data.organization : "",
      department: (data && data.department) || "",
      organization_type: (data && data.organization_type) || "",
      organization_name: (data && organizationName) || "",
      organization_type_field: props.editMode
        ? data && data.organization_type_field
        : "",
      level: (data && data.organization_level) || "",
      linkedin_profile_url: props.editMode
        ? data && data.linkedin_profile_link
        : "",
      gender: (data && data.gender_identity) || "",
      about_me: props.editMode ? data && data.profile_summary : "",
      leadership_roles: props.editMode ? data && data.leadership_roles : "",
      race: props.editMode ? previousRaceString : "",
      ethnicity: props.editMode ? data && data.ethnicity : "1",
      other_minority_identification: props.editMode
        ? previousMinorityIdentificationsString
        : "",
      date_of_birth: props.editMode ? data && data.birthdate : "",
      country_code: props.editMode ? countryCode : "",
      phone: props.editMode ? data && data.phone : "",
      phone_type: props.editMode && data ? data && data.phone_type : "",
      work_type: props.editMode && data ? data && data.work_type : "",
      email: data && data.email ? data.email : "",
      address: props.editMode ? data && data.address : "",
      city: props.editMode ? data && data.city : "",
      zip_code: props.editMode ? data && data.postal_code : "",
      country: props.editMode ? data && data.country : "",
      state: props.editMode ? data && data.state : "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.editMode) {
        let code = String(values.country_code).substr(
          String(values.country_code).lastIndexOf("|") + 1
        );
        let newUserData = {
          about_me: values.about_me,
          address: values.address,
          city: values.city,
          country: values.country,
          date_of_birth:
            values.date_of_birth !== ""
              ? new Date(values.date_of_birth).toISOString()
              : "",
          department: values.department,
          email: values.email || data.email,
          ethnicity: values.ethnicity ?? "1",
          first_name: values.first_name || data.first_name,
          last_name: values.last_name || data.last_name,
          leadership_roles: values.leadership_roles,
          linkedin_profile_url: values.linkedin_profile_url,
          organization: values.organization || data.organization,
          phone: values.phone,
          professional_title: values.professional_title,
          pronouns: values.pronouns,
          race: values.race,
          state: values.state,
          zip_code: values.zip_code,
          organization_type: values.organization_type,
          organization_type_field: values.organization_type_field,
          organization_name: values.organization_name,
          level: values.level,
          gender: values.gender,
          other_minority_identification: values.other_minority_identification,
          country_code: code,
          phone_type: values.phone_type,
          work_type: values.work_type,
          id: data.id,
          postal_code: values.zip_code,
        };

        if (profileFile) {
          let data = newUserData;
          newUserData = {
            ...data,
            file: profileFile,
          };
        } else {
          let data = newUserData;
          newUserData = {
            ...data,
            // file: {},
          };
        }
        dispatch(
          EMDoUpdateInformationAboutUserAction({
            userData: { ...newUserData },
            id: authData.profile_id,
          })
        );
      } else {
        setChangePage(!changePage);
        setUserData(values);
      }
    },
  });

  const formikForPasswordConfirmation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("This field is required")
        .min(8, "Password is too short - should be 8 characters minimum.")
        .matches(
          // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      confirm_password: Yup.string()
        .required("Enter Your Password Again")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
    }),
    onSubmit: (values) => {
      let newData = data;
      // let newUserData = {
      //   ...userData,
      //   ...values,
      //   ...newData,
      // }

      let code = String(userData.country_code).substr(
        String(userData.country_code).lastIndexOf("|") + 1
      );

      let newUserData = {
        about_me: userData.about_me,
        address: userData.address,
        city: userData.city,
        country: userData.country,
        date_of_birth:
          userData.date_of_birth !== ""
            ? new Date(userData.date_of_birth).toISOString()
            : "",
        department: userData.department,
        email: userData.email || newData.email,
        ethnicity: userData.ethnicity ?? "1",
        first_name: userData.first_name || newData.first_name,
        last_name: userData.last_name || newData.last_name,
        leadership_roles: userData.leadership_roles,
        linkedin_profile_url: userData.linkedin_profile_url,
        organization: userData.organization || newData.organization,
        phone: userData.phone,
        professional_title: userData.professional_title,
        pronouns: userData.pronouns,
        race: userData.race ?? "",
        state: userData.state,
        zip_code: userData.zip_code,
        organization_type: userData.organization_type,
        organization_type_field: userData.organization_type_field,
        level: userData.level,
        gender: userData.gender,
        other_minority_identification: userData.other_minority_identification ?? "",
        country_code: code,
        phone_type: userData.phone_type,
        work_type: userData.work_type ?? "",
        organization_name: userData.organization_name,

        //page 2
        ...values,
        //newData
        key: newData.key,
        role: newData.role,
        oranizaion_id: newData.oranizaion_id,
      };
      if (profileFile) {
        let data = newUserData;
        newUserData = {
          ...data,
          file: profileFile,
        };
      } else {
        let data = newUserData;
        newUserData = {
          ...data,
          // file: {},
        };
      }

      let query = "";
      if (key) {
        query = `?key=${key}`;
      }
      if (acceptTermsAndConditions == true) {
        dispatch(
          EMDoCreateUserAction({
            userData: { ...newUserData },
            queryData: `${query}`,
            key: "user_create_success",
          })
        );
      } else {
        setAcceptTermsAndConditionError({
          state: true,
          errorMessage: "Please accept terms and conditions",
        });
      }
    },
  });

  return (
    <EMPage
      className={classes.root}
      title={props.editMode ? null : "Sign Up 1/2"}
    >
      <Grid container component="main" className={classes.root}>
      {!isSmallScreen && (
        <Grid item xs={false} sm={6} md={6}>
          <img src={BackgroundImage} alt="Logo" className={classes.image} />
        </Grid>
        )}
        <div style={{ position: "absolute", top: 20, right: 20 }}>
          {props.editMode ? (
            <IconButton
              onClick={() => {
                dispatch({ type: PROFILE_EDIT_TOGGLE, payload: false });
              }}
            >
              <CloseRounded color="secondary" />
            </IconButton>
          ) : null}
        </div>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          component={Paper}
          elevation={2}
          square
          className={classes.scroll}
        >
          <div className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={6} style={{paddingTop:'60px'}}>
                <img src={ZequalityLogo} alt="Logo" className={classes.logo} />
              </Grid>
            </Grid>
            {changePage ? (
              <div>
                <Grid container justify="space-between">
                  <Typography
                    inline
                    variant="body1"
                    align="left"
                    gutterBottom
                    style={{ fontSize: "25px", fontWeight: "bold" }}
                  >
                    {!props.editMode
                      ? "Create your user profile - Step 1/2"
                      : null}
                  </Typography>
                </Grid>
                <Grid
                  style={{ marginBottom: "2%" }}
                  container
                  alignItems="center"
                  justify="flex-start"
                >
                  {profileUrl ? (
                    <Avatar
                      src={profileUrl}
                      color="primary"
                      className={classes.AvatarSize}
                    ></Avatar>
                  ) : data && data.profile_pic_file ? (
                    <Avatar
                      className={classes.AvatarSize}
                      src={
                        process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                        data.profile_pic_file
                      }
                    />
                  ) : (
                    <Avatar
                      className={classes.AvatarSize}
                      src="/broken-image.jpg"
                    />
                  )}
                  <Box className={classes.headerPadding}>
                    <Typography className={classes.header}>
                      {"Profile Photo"}
                    </Typography>
                    <Box style={{ marginTop: "2%" }}>
                      <EMImageCropper
                        onCancel={profileUrl}
                        id={"profileIcon"}
                        changeHandler={profilePicFileChangeHandler}
                        accept={[".png", ".jpg", ".jpeg"]}
                        fileSize={11534336}
                        supportedFormats={[
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                        ]}
                        maxFileSize={"10MB"}
                        aspectRatio={187.63 / 200}
                        minfilesize={71680}
                        minFileSize={"70KB"}
                      />
                    </Box>
                  </Box>
                </Grid>
                <form
                  className={classes.form}
                  onSubmit={formikForUserData.handleSubmit}
                >
                  <Grid container className={classes.form} spacing={1}>
                    <Grid item xs={12}>
                      <EMSimpleTextFieldView
                        required
                        label={"Email Address"}
                        id="email_address"
                        name="email"
                        fullwidth
                        error={Boolean(
                          formikForUserData.touched.email &&
                            formikForUserData.errors.email
                        )}
                        helperText={
                          formikForUserData.touched.email &&
                          formikForUserData.errors.email
                        }
                        onBlur={formikForUserData.handleBlur}
                        onChange={formikForUserData.handleChange}
                        type="email"
                        value={formikForUserData.values.email}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.form}>
                    <Grid item xs={6}>
                      <EMSimpleTextFieldView
                        required
                        label={"First Name"}
                        id="first_name"
                        name="first_name"
                        value={formikForUserData.values.first_name}
                        onChange={formikForUserData.handleChange}
                        // error={Boolean(
                        //   formikForUserData.touched.first_name &&
                        //     formikForUserData.errors.first_name
                        // )}
                        helperText={
                          formikForUserData.touched.first_name &&
                          formikForUserData.errors.first_name
                        }
                        onBlur={formikForUserData.handleBlur}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EMSimpleTextFieldView
                        required
                        label={"Last Name"}
                        id="last_name"
                        name="last_name"
                        value={formikForUserData.values.last_name}
                        onChange={formikForUserData.handleChange}
                        // error={Boolean(
                        //   formikForUserData.touched.last_name &&
                        //     formikForUserData.errors.last_name
                        // )}
                        helperText={
                          formikForUserData.touched.last_name &&
                          formikForUserData.errors.last_name
                        }
                        onBlur={formikForUserData.handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className={classes.form}>
                    <Grid item xs={6}>
                    <Tooltip title={`Your role in the organization, such as "Member."  If you are an office bearer, write your exact role here.`} arrow>
                      <EMSimpleTextFieldView
                        required
                        label={"Role"}
                        id="professional_title"
                        name="professional_title"
                        fullwidth
                        value={formikForUserData.values.professional_title}
                        onChange={formikForUserData.handleChange}
                        error={Boolean(
                          formikForUserData.touched.professional_title &&
                            formikForUserData.errors.professional_title
                        )}
                        helperText={
                          formikForUserData.touched.professional_title &&
                          formikForUserData.errors.professional_title
                        }
                        onBlur={formikForUserData.handleBlur}
                      />
    </Tooltip>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <EMMultiSelect
                        disabled={false}
                        label={"Select Pronouns"}
                        objKey={"pronoun"}
                        prevData={previousPronouns}
                        data={commonData.pronouns}
                        multiple={true}
                        onChange={(selectedPronouns) => {
                          let pronouns = selectedPronouns.join(", ");
                          formikForUserData.setFieldValue("pronouns", pronouns);
                        }}
                        formik={{ errors: "", touched: false }}
                      />
                    </Grid> */}
                  </Grid>
                  <Grid container spacing={1} className={classes.form}>
                    <Grid item xs={6}>
                      <EMSimpleTextFieldView
                        required
                        label={"Zequality Organization"}
                        id="organization"
                        name="organization"
                        value={formikForUserData.values.organization}
                        onChange={formikForUserData.handleChange}
                        error={Boolean(
                          formikForUserData.touched.organization &&
                            formikForUserData.errors.organization
                        )}
                        helperText={
                          formikForUserData.touched.organization &&
                          formikForUserData.errors.organization
                        }
                        onBlur={formikForUserData.handleBlur}
                        disabled
                      />
                    </Grid>
                    <Grid container direction="row" item xs={6}>
                      <Grid item xs={12}>
                        <EMSimpleTextFieldView
                          required
                          label={"Organization Type"}
                          id="organization_type"
                          name="organization_type"
                          value={formikForUserData.values.organization_type}
                          onChange={formikForUserData.handleChange}
                          // error={Boolean(
                          //   formikForUserData.touched.organization &&
                          //     formikForUserData.errors.organization
                          // )}
                          // helperText={
                          //   formikForUserData.touched.organization &&
                          //   formikForUserData.errors.organization
                          // }
                          // onBlur={formikForUserData.handleBlur}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} direction="row" item xs={12}>
                    <Grid container direction="row" item xs={6}>
                      <Grid item xs={12}>
                        <EMSimpleTextFieldView
                          required
                          label={"Organization Name"}
                          id="organization_name"
                          name="organization_name"
                          onChange={formikForUserData.handleChange}
                          value={formikForUserData.values.organization_name}
                          fullwidth
                          disabled={
                            (data && data.organization_type == "Company") ||
                            false
                          }
                          onBlur={formikForUserData.handleBlur}
                        />
                      </Grid>
                    </Grid>
                    <Grid container direction="row" item xs={6}>
                      <EMSingleSelect
                        label={"Select Centers"}
                        objKey={"department"}
                        required={false}
                        data={commonData.org_type_departments}
                        value={formikForUserData.values.department}
                        onChange={(selectedDepartment) => {
                          let department = selectedDepartment;
                          formikForUserData.setFieldValue(
                            "department",
                            department
                          );
                        }}
                        error={Boolean(
                          formikForUserData.touched.department &&
                            formikForUserData.errors.department
                        )}
                        helperText={
                          formikForUserData.touched.department &&
                          formikForUserData.errors.department
                        }
                      />
                    </Grid>
                    <Grid container direction="row" item xs={4}>
                      {/* <Grid item xs={12}>
                        <EMSingleSelect
                          required={false}
                          disabled={false}
                          label={"Select Level"}
                          objKey={"level"}
                          data={commonData.org_type_levels}
                          onChange={(selectedLevel) => {
                            let level = selectedLevel;
                            formikForUserData.setFieldValue("level", level);
                          }}
                          value={formikForUserData.values.level}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                  {/* <Grid direction="row" spacing={1} container item xs={12}>
                    <Grid item xs={4}>
                      <EMSimpleTextFieldView
                        label={"Organization Type Field"}
                        id="organization_type_field"
                        name="organization_type_field"
                        onChange={formikForUserData.handleChange}
                        value={formikForUserData.values.organization_type_field}
                        fullwidth
                        onBlur={formikForUserData.handleBlur}
                      />
                    </Grid>
                  </Grid> */}
                    <Grid item md={0} xs={0} style={{display:"none"}}>
                      <EMSimpleTextFieldView
                        // required
                        label={"City"}
                        id="city"
                        name="city"
                        value={formikForUserData.values.city}
                        onChange={formikForUserData.handleChange}
                        error={Boolean(
                          formikForUserData.touched.city &&
                            formikForUserData.errors.city
                        )}
                        helperText={
                          formikForUserData.touched.city &&
                          formikForUserData.errors.city
                        }
                        onBlur={formikForUserData.handleBlur}
                      />
                    </Grid>
                  <Grid container spacing={1} className={classes.textfield}>

                    <Grid item md={6} xs={12}>
                      <EMCountryAndStateSelector
                        // required={true}
                        idCountry="country"
                        idState="state"
                        showOnlyCountry={true}
                        handleChangeCountry={formikForUserData.handleChange}
                        handleChangeState={formikForUserData.handleChange}
                        valueCountry={formikForUserData.values.country}
                        valueState={formikForUserData.values.state}
                        errorCountry={
                          formikForUserData.touched.country &&
                          Boolean(formikForUserData.errors.country)
                        }
                        helperTextCountry={
                          formikForUserData.touched.country &&
                          formikForUserData.errors.country
                        }
                        errorState={
                          formikForUserData.touched.state &&
                          Boolean(formikForUserData.errors.state)
                        }
                        helperTextState={
                          formikForUserData.touched.state &&
                          formikForUserData.errors.state
                        }
                      />
                    </Grid>
                 
                    <Grid item md={3} xs={6}>
                      <EMSimpleTextFieldView
                        // required
                        label={"Zip Code"}
                        id="zip_code"
                        name="zip_code"
                        value={formikForUserData.values.zip_code}
                        onChange={formikForUserData.handleChange}
                        error={Boolean(
                          formikForUserData.touched.zip_code &&
                            formikForUserData.errors.zip_code
                        )}
                        helperText={
                          formikForUserData.touched.zip_code &&
                          formikForUserData.errors.zip_code
                        }
                        onBlur={formikForUserData.handleBlur}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{display:"none"}}>
                    <EMSimpleTextFieldView
                      label={"Linkedin Profile URL"}
                      id="linkedin_profile_url"
                      name="linkedin_profile_url"
                      onChange={formikForUserData.handleChange}
                      value={formikForUserData.values.linkedin_profile_url}
                      fullwidth
                      error={Boolean(
                        formikForUserData.touched.linkedin_profile_url &&
                          formikForUserData.errors.linkedin_profile_url
                      )}
                      helperText={
                        formikForUserData.touched.linkedin_profile_url &&
                        formikForUserData.errors.linkedin_profile_url
                      }
                      onBlur={formikForUserData.handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label={"About Me "}
                      InputLabelProps={{ style: { color: "#4d4d4d" } }}
                      id="about_me"
                      name="about_me"
                      placeholder={"Maximum 2600 characters are allowed"}
                      value={formikForUserData.values.about_me}
                      onChange={formikForUserData.handleChange}
                      error={Boolean(
                        formikForUserData.touched.about_me &&
                          formikForUserData.errors.about_me
                      )}
                      helperText={
                        formikForUserData.touched.about_me &&
                        formikForUserData.errors.about_me
                      }
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 2600 }}
                      fullWidth={true}
                    />
                  </Grid>
                  {/* <Grid container spacing={1} className={classes.form}>
                    <Grid item xs={12}>
                      <EMSimpleTextFieldView
                        label={
                          "Leadership Roles (such as ERG leader, non-profit organization or community leader etc.)"
                        }
                        id="leadership_roles"
                        name="leadership_roles"
                        onChange={formikForUserData.handleChange}
                        value={formikForUserData.values.leadership_roles}
                        fullwidth
                      />
                    </Grid>
                  </Grid> */}
                  <Grid
                    container
                    spacing={1}
                    style={{ paddingTop: "2%", paddingBottom: "2%" }}
                  >
                    <Grid item xs={12}>
                      <Typography
                        style={{ fontWeight: "bolder", fontSize: "small" }}
                      >
                        The following information is gathered only for
                        demographic and statistical purposes.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                      <EMSingleSelect
                        required={true}
                        label={"Select Gender"}
                        objKey={"gender"}
                        data={commonData.genders}
                        onChange={(selectedGender) => {
                          let gender = selectedGender;
                          const pronouns= gender === "Man" ? ['He/Him'] : gender === "Woman" ? ['She/Her']:["Other"]
                          formikForUserData.setFieldValue("gender", gender);
                          formikForUserData.setFieldValue("pronouns", pronouns);
                        }}
                        value={formikForUserData.values.gender}
                        // error={Boolean(
                        //   formikForUserData.touched.department &&
                        //     formikForUserData.errors.department
                        // )}
                        // helperText={
                        //   formikForUserData.touched.department &&
                        //   formikForUserData.errors.department
                        // }
                      />
                    </Grid>
                    <Grid item xs={6} style={{display:"none"}}> 
                      <EMDatepicker
                        //required
                        disabled={true}
                        label={"Date of Birth"}
                        id={"date_of_birth"}
                        value={
                          formikForUserData.values.date_of_birth == null ||
                          formikForUserData.values.date_of_birth == undefined
                            ? " "
                            : formikForUserData.values.date_of_birth
                        }
                        onSelectDate={(date) => {
                          formikForUserData.setFieldValue(
                            "date_of_birth",
                            date
                          );
                        }}
                        //minDate={moment(new Date("01/01/1960"))}
                        error={formikForUserData.errors.date_of_birth}
                        helperText={formikForUserData.errors.date_of_birth}
                      />
                    </Grid>
                  </Grid>

                  {/* <Grid
                    style={{ marginTop: "1%", maxWidth: "100%" }}
                    container
                    spacing={1}
                    // className={classes.form}
                  >
                    <Grid item xs={6}>
                      <EMMultiSelect
                        required={true}
                        disabled={false}
                        label={"Select Race"}
                        objKey={"race"}
                        prevData={previousRace}
                        data={commonData.race}
                        multiple={true}
                        onChange={(selectedPronouns) => {
                          let pronouns = selectedPronouns.join(", ");
                          formikForUserData.setFieldValue("race", pronouns);
                        }}
                        formik={{ errors: "", touched: false }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EMGroupedSingleSelect
                        data={commonData.ethnicity}
                        label={"Ethnicity"}
                        objKey={"ethnicity_name"}
                        objSecondaryKey={"ethnicity_sub_type"}
                        objTertKey={"name"}
                        objQuatKey={"combined"}
                        onChange={(selectedEthnicity) => {
                          let ethnicity = selectedEthnicity;
                          formikForUserData.setFieldValue(
                            "ethnicity",
                            ethnicity
                          );
                        }}
                        errors={""}
                        required={true}
                        value={formikForUserData.values.ethnicity}
                      />
                    </Grid>
                  </Grid> */}
                  {/* <Grid container spacing={1} className={classes.form}>
                    <Grid item xs={6}>
                      <EMMultiSelect
                        disabled={false}
                        label={"Select Other Minority Identifications"}
                        objKey={"minority"}
                        prevData={previousMinorityIdentifications}
                        data={commonData.minority_identification}
                        multiple={true}
                        onChange={(selectedMinority) => {
                          let pronouns = selectedMinority.join(", ");
                          formikForUserData.setFieldValue(
                            "other_minority_identification",
                            pronouns
                          );
                        }}
                        required={true}
                        formik={{ errors: "", touched: false }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <EMSingleSelect
                        disabled={false}
                        label={"Select Work Type"}
                        objKey={"work_types"}
                        required={false}
                        data={commonData.work_types}
                        value={formikForUserData.values.work_type}
                        onChange={(selectedWorkType) => {
                          let workType = selectedWorkType;
                          formikForUserData.setFieldValue(
                            "work_type",
                            workType
                          );
                        }}
                      />
                    </Grid>
                  </Grid> */}
                  {/* <Grid spacing={1} container direction="row" item xs={12}>
                    <Grid spacing={1} container direction="row" item xs={8}>
                      <Grid item xs={4}>
                        <EMSingleSelect
                          required={true}
                          label={"Country Code"}
                          objKey={"code"}
                          objSecondaryKey={"name"}
                          data={commonData.country_codes}
                          value={formikForUserData.values.country_code}
                          onChange={(selectedCountryCode) => {
                            let countryCode = selectedCountryCode;
                            formikForUserData.setFieldValue(
                              "country_code",
                              countryCode
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <EMSimpleTextFieldView
                          required
                          label={"Phone"}
                          id="phone"
                          name="phone"
                          value={formikForUserData.values.phone}
                          onChange={formikForUserData.handleChange}
                          error={Boolean(
                            formikForUserData.touched.phone &&
                              formikForUserData.errors.phone
                          )}
                          helperText={
                            formikForUserData.touched.phone &&
                            formikForUserData.errors.phone
                          }
                          onBlur={formikForUserData.handleBlur}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <EMSingleSelect
                        required={true}
                        value={formikForUserData.values.phone_type}
                        label={"Select Phone Type"}
                        objKey={"phone_types"}
                        data={commonData.phone_types}
                        onChange={(selectedPhoneType) => {
                          let phoneType = selectedPhoneType;
                          formikForUserData.setFieldValue(
                            "phone_type",
                            phoneType
                          );
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <EMSimpleTextFieldView
                      required
                      label={"House Address"}
                      id="address"
                      name="address"
                      fullwidth
                      value={formikForUserData.values.address}
                      onChange={formikForUserData.handleChange}
                      error={Boolean(
                        formikForUserData.touched.address &&
                          formikForUserData.errors.address
                      )}
                      helperText={
                        formikForUserData.touched.address &&
                        formikForUserData.errors.address
                      }
                      onBlur={formikForUserData.handleBlur}
                    />
                  </Grid> */}

                  <Grid
                    direction="row"
                    container
                    spacing={3}
                    style={{ marginTop: "2%",marginBottom:'13%' }}
                  >
                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Button color="primary" variant="contained" type="submit">
                        {onButtonLable}
                      </Button>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          formikForUserData.resetForm();
                          setPreviousPronouns([]);
                          setPreviousRace([]);
                          setPreviousPronounsString("");
                          setPreviousRaceString("");
                          setPreviousMinorityIdentifications([]);
                          setPreviousMinorityIdentificationsString("");
                          formikForUserData.setFieldValue("date_of_birth", "");
                          setProfileFile();
                          setProfileUrl();
                          if (props.editMode) {
                            dispatch({
                              type: PROFILE_EDIT_TOGGLE,
                              payload: false,
                            });
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>

                    {formikForUserData.errors.name && (
                      <div>{formikForUserData.errors.name}</div>
                    )}
                  </Grid>
                </form>
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <Grid container>
                  <Typography
                    inline
                    variant="body1"
                    align="left"
                    gutterBottom
                    style={{ fontSize: "25px", fontWeight: "bold" }}
                  >
                    Create your user profile - Step 2/2
                  </Typography>
                </Grid>
                <form
                  className={classes.form}
                  onSubmit={formikForPasswordConfirmation.handleSubmit}
                >
                  <TextField
                    className={classes.TextField}
                    InputLabelProps={{ style: { color: "#4d4d4d" } }}
                    FormHelperTextProps={{ style: { color: "red" } }}
                    fullWidth
                    helperText={
                      formikForPasswordConfirmation.touched.password &&
                      formikForPasswordConfirmation.errors.password
                    }
                    label="Password"
                    margin="normal"
                    name="password"
                    value={formikForPasswordConfirmation.values.password}
                    onBlur={formikForPasswordConfirmation.handleBlur}
                    onChange={formikForPasswordConfirmation.handleChange}
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ color: "#2F92D6" }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ bgcolor: "blue" }}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    className={classes.TextField}
                    InputLabelProps={{ style: { color: "#4d4d4d" } }}
                    FormHelperTextProps={{ style: { color: "red" } }}
                    fullWidth
                    helperText={formikForPasswordConfirmation.errors.confirm_password}
                    label="Confirm Password"
                    name="confirm_password"
                    id="confirm_password"
                    margin="normal"
                    value={
                      formikForPasswordConfirmation.values.confirm_password
                    }
                    onBlur={formikForPasswordConfirmation.handleBlur}
                    onChange={formikForPasswordConfirmation.handleChange}
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            style={{ color: "#2F92D6" }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ bgcolor: "blue" }}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    style={{ paddingTop: "1%" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={acceptTermsAndConditions}
                          onChange={(event) => {
                            setAcceptTermsAndCondition(event.target.checked);
                            if (event.target.checked == true) {
                              setAcceptTermsAndConditionError({
                                state: false,
                                errorMessage: "",
                              });
                            }
                          }}
                        />
                      }
                      label={
                        <Box style={{ display: "flex" }}>
                          <Typography
                            variant="body2"
                            style={{
                              fontSize: "12px",
                              fontFamily: "inherit",
                            }}
                          >
                            By checking this box, you are agreeing to our
                          </Typography>
                          &nbsp;
                          <RouterLink
                            target="blank"
                            to={"/terms&conditions"}
                            component="button"
                            variant="body2"
                            style={{
                              fontSize: "12px",
                              color: "#2F92D6",
                              fontFamily: "inherit",
                            }}
                          >
                            terms of use.
                          </RouterLink>
                        </Box>
                      }
                    />
                  </Grid>
                  <FormHelperText style={{ color: "red" }}>
                    {acceptTermsAndConditionsError.errorMessage}
                  </FormHelperText>
                  <Grid
                    style={{ paddingTop: "16px" }}
                    direction="row"
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Button color="primary" type="submit" variant="contained">
                        Submit
                      </Button>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                      <Button
                        variant="outlined"
                        onClick={formikForPasswordConfirmation.resetForm}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </EMPage>
  );
}
