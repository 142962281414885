import React,{useState,useEffect} from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box, CardHeader, CardMedia, Paper } from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import EMAddNewComment from "./EMAddNewComment";
import { useSelector, useDispatch } from "react-redux";
import EMLinkPreview from "./EMLinkPreview";
import { SvgIcon, IconButton, useMediaQuery } from "@material-ui/core";
import ReactPlayer from "react-player";
import Avatar from "@material-ui/core/Avatar";
import { red } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Parse from "html-react-parser";
import DeleteIcon from "@material-ui/icons/Delete";
// import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {
  USER_EXPAND_TEXT,
  USER_EXPAND_COMMENT,
  USER_SHOW_COMMENT,
  SHOW_POST_MEDIA_PREVIEW,
  PLAY_VIDEO_BY_ID
} from "../../redux/constants/UserPlatform/EMEventsConstant";
import EMPostContent from "./EMPostContent";
import { EMDoDeleteCommentFromPostAction } from "../../redux/actions/UserPlatform/Home/EMLatestPostPageAction";
import "react-quill/dist/quill.snow.css";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: "100%",
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    // justifyContent: 'space-around',
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  currentMediaOfPost: {
    "&:hover": {
      opacity: "0.3",
    },
  },
  editPost: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "80%",
    marginLeft: "10%",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "black",
    borderColor: "black",
    //  color:"white"
  },
  media: {
    height: "400px",
  },
  addComment: {
    width: "100%",
    display: "flex",
    // paddingTop: 8,
    height: "auto",
    alignItems: "center",
  },
  comments: {
    width: "100%",
  },
  commentButton: {
    marginRight: "3%",
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  commentButtonBeforeposting: { marginRight: "3%" },
  picker: {
    position: "fixed",
    marginTop: "10%",
    marginLeft: "58%",
  },
  comment: {
    // width: "2%",
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
  avatar: {
    backgroundColor: red[500],
  },
  myTextStyle: {
    textDecoration: "none",
    color: "blue",
    float: "right",
    marginTop: "2%",
    // marginRight: "3%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  myTextStyle2: {
    textDecoration: "none",
    color: "blue",
    float: "right",
    // marginRight: "3%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  title: {
    fontWeight: "bolder",
    fontSize: "14px",
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak:"break-word",
    overflow: "hidden",
  },
  typo:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak:"break-word",
    overflow: "hidden",
  },
  groupAvtar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  like: {
    // height: "2%",
    // width: "2%",
    "&:hover": {
      color: "auqa",
      cursor: "pointer",
    },
  },
}));

export default function EMSingleEventDetail({
  post,
  likeHandler,
  backToEvents,
  searchedEvent
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth || {});
  const [adminIds, setadminIds] = useState([])
  const theme = useTheme();
  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(()=>{
    setadminIds(post?.admin_ids)
  },[post])

  function ChangeDate(date) {
    let newDate = new Date(date);
    var hours = newDate.getHours();
    var minutes = newDate.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    let dateToDisplay =
      newDate.getDate() +
      " " +
      monthNames[newDate.getMonth()] +
      " " +
      newDate.getFullYear() +
      " " +
      strTime;

    return dateToDisplay;
  }

  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time = time.slice(0, 3);
      time[4] = +time[0] < 12 ? " AM" : " PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }
  const isAdmin= adminIds?.includes(userData?.profile_id)
  console.log("ids",adminIds, userData?.profile_id);
  return (
    <div style={{ marginBottom: "3.5%" }}>
      <Paper elevation={2}>
        <Card className={classes.card}>
          <div style={{ padding: 10 }}>
            <Button
              onClick={backToEvents}
              variant="outlined"
              startIcon={<KeyboardBackspaceIcon />}
            >
              BACK TO EVENTS
            </Button>
            <div
              style={{
                position: "relative",
                paddingBottom: "2%",
                paddingTop: "2%",
              }}
            >
              {post.banner_file ? (
                <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  // backgroundColor: "#d3d3d3",
                }}
              >

                <img
                  alt=""
                  style={{
                    flexShrink: 0,
                    minWidth: "100%",
                    minHeight: "100%",
                  }}
                  src={
                    post.banner_file &&
                    process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(0, -1) +
                      post.banner_file
                  }
                />
              </div>
              ) : null}
            </div>

            <CardHeader
              title={
                <Typography
                  variant="h5"
                  component="h5"
                  style={{ fontWeight: "bolder" }}
                >
                  {post.title}
                </Typography>
              }
              subheader={
                <>
                  <Typography style={{ fontWeight: "bolder" }}>
                    {post.groupName}
                  </Typography>
                  <Typography>
                    {post.event_start}, {tConvert(post.event_start_time)} -{" "}
                    {post.event_end}, {tConvert(post.event_end_time)} (
                    {post.timeZone})
                  </Typography>

                  <Typography style={{ fontWeight: "bolder" }}>
                    {post.is_online ? "Online Event" : "Offline Event"}
                  </Typography>
                </>
              }
              action={
                post.registration_link !== "" && (
                  <a
                  href={
                    post.registration_link.includes("http")
                      ? post.registration_link
                      : "//" + post.registration_link
                  }
                    rel="noreferrer"
                    target="_blank"
                    onClick="javascript.function();"
                    style={{ textDecoration: "none" }}
                  >
                    <Button style={{marginTop:"0px"}} color="primary" variant="contained">
                      GO TO LINK
                    </Button>
                  </a>
                )
              }
            ></CardHeader>
            <CardContent>
              {post && post.expandText ? (
                <>
                  <Typography>
                    <div
                      className="ql-editor"
                      style={{ padding: 0, overflow: "hidden" }}
                    >
                      {Parse(post.postText)}
                    </div>
                    {/* <Typography > */}
                    <a
                      className={classes.myTextStyle}
                      onClick={() => {
                        dispatch({
                          type: USER_EXPAND_TEXT,
                          payload: { id: post.id, content: false },
                        });
                      }}
                    >
                      {" ...See Less"}
                    </a>
                    {/* </Typography> */}
                  </Typography>
                  {post.thumbnail_link !== null &&
                  post.thumbnail_link.thumbnail_link.length !== 0 ? (
                    <EMLinkPreview
                      style={{ marginTop: "5%" }}
                      preview_description={post.thumbnail_link.description}
                      preview_image={post.thumbnail_link.image}
                      preview_title={post.thumbnail_link.title}
                      url={post.thumbnail_link.thumbnail_link}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  <Typography>
                    <div
                      className="ql-editor"
                      style={{ padding: 0, overflow: "hidden" }}
                    >
                      {Parse(post.postText.substring(0, 250))}
                    </div>
                    {post.postText && post.postText.length > 250 ? (
                      <Typography className={classes.myTextStyle}>
                        <a
                          onClick={() => {
                            dispatch({
                              type: USER_EXPAND_TEXT,
                              payload: { id: post.id, content: true },
                            });
                          }}
                        >
                          {" ...See More"}
                        </a>
                      </Typography>
                    ) : null}
                  </Typography>
                  {post.thumbnail_link !== null &&
                  post.thumbnail_link.thumbnail_link.length !== 0 ? (
                    <EMLinkPreview
                      style={{ marginTop: "5%" }}
                      preview_description={post.thumbnail_link.description}
                      preview_image={post.thumbnail_link.image}
                      preview_title={post.thumbnail_link.title}
                      url={post.thumbnail_link.thumbnail_link}
                    />
                  ) : null}
                </>
              )}
            </CardContent>
            <div>
              {post.documents &&
                post.documents.map((media, key) => (
                  <Card className={classes.postMedia} id="document1">
                    <a
                      href={
                        process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                        media.file_name
                      }
                      style={{ color: "blue", fontSize: "small" }}
                      target="_blank"
                    >
                      {media &&
                        media.file_name &&
                        media.file_name.substr(
                          media && media.file_name.lastIndexOf("/") + 1,
                          media && media.length
                        )}
                    </a>
                  </Card>
                ))}
              <ImageList
                rowHeight={"10%"}
                style={{
                  marginTop:
                    post.postText.length > 250 &&
                    post.totalCombinedMedia.length > 0
                      ? "3%"
                      : "0%",
                }}
              >
                {post.totalCombinedMedia &&
                  post.totalCombinedMedia.map((media, key) =>
                    key < 6 ? (
                      <ImageListItem
                        key={1}
                        cols={
                          post.totalCombinedMedia &&
                          post.totalCombinedMedia.length == 1 || matchesSmallScreen 
                            ? 2
                            : 1
                        }
                  
                      >
                        {media.type == "images" ? (
                          <div
                            style={{
                              width: "99%",
                              height: "100%",
                              whiteSpace: "nowrap",
                              textAlign: "center",
                              // backgroundColor: "grey",
                            }}
                            onClick={() => {
                              if (!matchesSmallScreen) {
                              dispatch({
                                type: SHOW_POST_MEDIA_PREVIEW,
                                payload: {
                                  id: post.id,
                                  showPreview: true,
                                },
                              });
                            }}}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                height: "100%",
                                verticalAlign: "middle",
                              }}
                            ></span>
                            <img
                              className={classes.currentMediaOfPost}
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                verticalAlign: "middle",
                              }}
                              onMouseEnter={() => {}}
                              src={
                                process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                                media.file_name
                              }
                              alt={"df"}
                            />
                          </div>
                        ) : media.type == "videos" ? (
                          <div
                            style={{ position: "relative", paddingTop: "56.25%" }}
                          >
                            <ReactPlayer
                              width="100%"
                              controls={true}
                              playing={media.isPlaying}
                              onEnded={() => {
                                dispatch({
                                  type: PLAY_VIDEO_BY_ID,
                                  payload: {
                                    postId: post.id,
                                    videoId: media.id,
                                    media: {
                                      file_name: media.file_name,
                                      file_path: media.file_path,
                                      id: media.id,
                                      isPlaying: false,
                                      type: media.type,
                                    },
                                    fromPreview:false
                                  },
                                });
                              }}
                              onPause={() => {
                                dispatch({
                                  type: PLAY_VIDEO_BY_ID,
                                  payload: {
                                    postId: post.id,
                                    videoId: media.id,
                                    media: {
                                      file_name: media.file_name,
                                      file_path: media.file_path,
                                      id: media.id,
                                      isPlaying: false,
                                      type: media.type,
                                    },
                                    fromPreview:false
                                  },
                                });
                              }}
                              onPlay={() => {
                                dispatch({
                                  type: PLAY_VIDEO_BY_ID,
                                  payload: {
                                    postId: post.id,
                                    videoId: media.id,
                                    media: {
                                      file_name: media.file_name,
                                      file_path: media.file_path,
                                      id: media.id,
                                      isPlaying: true,
                                      type: media.type,
                                    },
                                    fromPreview:false
                                  },
                                });
                              }}
                              height="100%"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                              }}
                              url={
                                process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                                media.file_name
                              }
                            />
                          </div>
                        ) : media.type === "video_link" ? (
                          <div
                            style={{ position: "relative", paddingTop: "56.25%" }}
                          >
                            <ReactPlayer
                              config={{
                                youtube: {
                                  playerVars: { origin: "https://www.youtube.com" },
                                },
                              }}
                              width="100%"
                              height="100%"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%",
                              }}
                              controls={true}
                              playing={media.isPlaying}
                              onEnded={() => {
                                dispatch({
                                  type: PLAY_VIDEO_BY_ID,
                                  payload: {
                                    postId: post.id,
                                    videoId: media.id,
                                    media: {
                                      file_name: media.file_name,
                                      file_path: media.file_path,
                                      id: media.id,
                                      isPlaying: false,
                                      type: media.type,
                                    },
                                    fromPreview:false
                                  },
                                });
                              }}
                              onPause={() => {
                                dispatch({
                                  type: PLAY_VIDEO_BY_ID,
                                  payload: {
                                    postId: post.id,
                                    videoId: media.id,
                                    media: {
                                      file_name: media.file_name,
                                      file_path: media.file_path,
                                      id: media.id,
                                      isPlaying: false,
                                      type: media.type,
                                    },
                                    fromPreview:false
                                  },
                                });
                              }}
                              onPlay={() => {
                                dispatch({
                                  type: PLAY_VIDEO_BY_ID,
                                  payload: {
                                    postId: post.id,
                                    videoId: media.id,
                                    media: {
                                      file_name: media.file_name,
                                      file_path: media.file_path,
                                      id: media.id,
                                      isPlaying: true,
                                      type: media.type,
                                    },
                                    fromPreview:false
                                  },
                                });
                              }}
                              url={media.file_path}
                            />
                          </div>
                        ) : null}
                      </ImageListItem>
                    ) : null
                  )}
              </ImageList>
            </div>
            <div>
              {post.totalCombinedMedia &&
          post.totalCombinedMedia.length > 6 &&
          !matchesSmallScreen ? (
                // <Card className={classes.postMedia}>
                <Typography className={classes.myTextStyle}>
                  <a
                    style={{ color: "blue" }}
                    onClick={() => {
                      dispatch({
                        type: SHOW_POST_MEDIA_PREVIEW,
                        payload: {
                          id: post.id,
                          showPreview: true,
                        },
                      });
                    }}
                  >
                    {" ...View all media"}
                  </a>
                </Typography>
              ) : // </Card>
              null}
            </div>
            <Dialog
              open={post.mediaView}
              onClose={() => {
                dispatch({
                  type: SHOW_POST_MEDIA_PREVIEW,
                  payload: { id: post.id, showPreview: false },
                });
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              maxWidth="lg"
            >
              <DialogContent
                style={{
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: 0,
                }}
              >
                {" "}
                <EMPostContent
                  post={post}
                  permission={true}
                  likehandler={likeHandler}
                  searched={searchedEvent}
                />
              </DialogContent>
            </Dialog>
            <CardActions>
              <>
                {post.liked ? (
                  <>
                    <ThumbUpAltIcon
                      color="primary"
                      className={classes.like}
                       onClick={post.likeButtonDisabled ? () => {} : likeHandler}
                    />
                    <Typography>{post.likes} Likes</Typography>
                  </>
                ) : (
                  <>
                    <ThumbUpAltIcon
                      color="action"
                      className={classes.like}
                       onClick={post.likeButtonDisabled ? () => {} : likeHandler}
                    />
                    <Typography>{post.likes} Likes</Typography>
                  </>
                )}
              </>
              <>
                {post.comments && post.comments.length > 0 ? (
                  <>
                    <ChatBubbleIcon
                      color="primary"
                      className={classes.comment}
                      onClick={() => {
                        dispatch({
                          type: USER_SHOW_COMMENT,
                          payload: { id: post.id, content: !post.showComments },
                        });
                      }}
                    />
                    <Typography style={{cursor:"pointer"}} onClick={() => {
                  dispatch({
                    type: USER_SHOW_COMMENT,
                    payload: {
                      id: post.id,
                      content: !post.showComments,
                      is_event: post.is_event,
                    },
                  });
                }}>{post.comments.length} Comments</Typography>
                  </>
                ) : (
                  <>
                    <ChatBubbleIcon
                      color="action"
                      className={classes.comment}
                    />
                    <Typography>
                      {post.comments && post.comments.length} Comments
                    </Typography>
                  </>
                )}
              </>
            </CardActions>
          </div>
          {post.showComments
            ? post.comments &&
              post.comments.map((comment) => {
                return (
                  <Card elevation={2} style={{ width: "100%" }}>
                    <CardHeader
                      avatar={
                        <div>
                          <Avatar
                            aria-label="recipe"
                            className={classes.avatar}
                            src={
                              process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.substr(
                                0,
                                process.env.REACT_APP_BACKEND_FILE_DOWNLOAD
                                  .length - 1
                              ) + comment.profileIcon
                            }
                            alt={
                              comment.first_name &&
                              comment.first_name.charAt(0).toUpperCase()
                            }
                          />
                        </div>
                      }
                      title={
                        <Box align="center" display="flex">
                          <Box align="start">
                            <Typography className={classes.title}>
                              {comment.first_name} {comment.last_name},&nbsp;
                            </Typography>
                          </Box>
                          <Box>
                            <Typography style={{ fontSize: "small" }} className={classes.typo}>
                              {comment.organisationName}
                            </Typography>
                          </Box>
                        </Box>
                      }
                      action={
                        <Box
                          alignItems="center"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {!matchesSmallScreen ? <Box>
                          <Typography
                            color="textSecondary"
                            style={{ fontSize: "small" }}
                          >
                            {comment.commentedOn &&
                              ChangeDate(comment.commentedOn)}
                          </Typography>
                        </Box>: null}
                          <Box>
                            {(comment.user_id == userData.profile_id &&
                            !searchedEvent )||isAdmin  ? (
                              <IconButton
                                style={{ marginLeft: "10%" }}
                                onClick={() => {
                                  let data = {
                                    postId: post.id,
                                    commentId: comment.comment_id,
                                  };
                                  dispatch(
                                    EMDoDeleteCommentFromPostAction(data)
                                  );
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : null}
                          </Box>
                        </Box>
                      }
                      subheader={
                        <>
                          <Typography>{comment.designation}</Typography>
                          {matchesSmallScreen ? <Box>
                          <Typography
                            color="textSecondary"
                            style={{ fontSize: "small" }}
                          >
                            {comment.commentedOn &&
                              ChangeDate(comment.commentedOn)}
                          </Typography>
                        </Box>: null}
                        </>
                      }
                    />
                    <CardContent>
                      {post.expandPostComment ? (
                        <Typography>
                          {Parse(comment.comment)}
                          <a
                            className={classes.myTextStyle2}
                            onClick={() => {
                              dispatch({
                                type: USER_EXPAND_COMMENT,
                                payload: { id: post.id, content: false },
                              });
                            }}
                          >
                            {comment.comment.length > 250
                              ? " ...See Less"
                              : null}
                          </a>
                        </Typography>
                      ) : (
                        <Typography>
                          {Parse(
                            comment.comment
                              ? comment.comment.substring(0, 250)
                              : ""
                          )}
                          <a
                            className={classes.myTextStyle2}
                            onClick={() => {
                              dispatch({
                                type: USER_EXPAND_COMMENT,
                                payload: { id: post.id, content: true },
                              });
                            }}
                          >
                            {comment.comment.length > 250
                              ? " ...See More"
                              : null}
                          </a>
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                );
              })
            : null}
        </Card>
      </Paper>
      <Paper
        elevation={2}
        className={classes.addComment}
        style={{ marginTop: "0.5%" }}
      >
        <EMAddNewComment post={post} />
      </Paper>
    </div>
  );
}

function PinIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M23.541,23.763l-9.431-5.9a2.321,2.321,0,0,0-3.306.958,2.745,2.745,0,0,0,.886,3.581l9.429,5.9a2.32,2.32,0,0,0,3.306-.959,2.739,2.739,0,0,0-.885-3.58Zm-7.778-6.378L23.1,21.973l2.495-7.521-5.065-3.167ZM10.356,36.706l7.017-9.233-3.144-1.966-3.873,11.2ZM28.625,10.292,22.339,6.36a1.74,1.74,0,0,0-2.479.72,2.057,2.057,0,0,0,.664,2.686L26.811,13.7a1.74,1.74,0,0,0,2.479-.72,2.057,2.057,0,0,0-.665-2.686Z"
        transform="translate(-10.356 -6.096)"
      />
    </SvgIcon>
  );
}

const ViewMoreOption = () => {
  return (
    <div>
      <div>John Doe</div>
    </div>
  );
};
