import {
  Paper,
  Box,
  Button,
  Grid,
  CircularProgress,
  Typography,
  Modal,
  Hidden,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import EMPage from "../../../components/commans/EMPage";
import EMDatatableSearchView from "../../../components/datatables/EMDatatableSearchView";
import EMGroupTable from "./EMGroupTable";
import EMAddEditGroup from "./EMAddEditGroup";
import { useSelector, useDispatch } from "react-redux";
import { findLast, isEmpty, findIndex, find } from "lodash";
import EMEntityDropdown from "../UserManagement/commons/EMEntityDropdown";
import { EMDoGetEntityInformationBasedOnPermissionAction } from "../../../redux/actions/EMAuthActions";
import { EMDoGetListOfNetworksForDropdownAction, EMDoGetPermissionSpecificToNetworkAction } from "../../../redux/actions/EMNetworkAdminActions";
import { NETWORK_ADMIN_UPDATE_GROUPS_LIST } from "../../../redux/constants";

export default function NetworkGroupManagement(props) {
  const [filter, setFilter] = useState("");
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState({});
  const [entity, setEntity] = React.useState();
  const [showNetworkError, setShowNetworkError] = React.useState();
  const [isError, setIsError] = React.useState(true);
  const [showLabels, setShowLabels] = React.useState({
    label: "",
    buttonLabel: "",
  });
  const [saveClicked, setDisabled] = React.useState(false);
  const userData = useSelector((state) => state.auth);
  const data = useSelector((state) => state.auth["entityDetails"]);
  const [permissionData, setPermissionData] = React.useState();
  const dispatch = useDispatch();
  
  const uiData = useSelector((state) => state.ui);

  //rolepermissions based on group selection
  const networkSpecificPermissions = useSelector(state => state.networkAdmin.networkSpecificRolesAndPermissions)

  useEffect(()=>{
    if(networkSpecificPermissions && entity){
      let permission = find(networkSpecificPermissions,{context:entity.context})
      let specificPermission = find(permission && permission.rolePermissions,{permission_name:"groups"})
      setPermissionData(specificPermission)
    }
  },[networkSpecificPermissions])

  //call dropdown Api
  useEffect(() => {
    dispatch(EMDoGetListOfNetworksForDropdownAction());
  }, []);

  useEffect(() => {
    if(entity){
      dispatch(EMDoGetPermissionSpecificToNetworkAction({id:entity.context, networkType:entity.entity_type}))
    }
  },[entity])

  /* open and close modal functionality */
  const handleClose = () => {
    setOpen(false);
    setEditData({});
  };

  useEffect(() => {
    if (!isEmpty(entity)) {
      setShowNetworkError("");
    }
  }, [entity]);

  const networkSelectionHandler = () => {
    if (isEmpty(entity)) {
      setShowNetworkError("Please Select Network Here");
      setIsError(false);
    } else {
      setShowNetworkError("");
      setIsError(true);
      setOpen(true);
    }
  };

  useEffect(() => {
    if (uiData["messages"] && uiData.loading !== true) {
      let refObj = findLast(uiData["messages"], { key: "group_add_edit" });
      if (refObj && refObj.type === "success") {
        setOpen(false);
        setDisabled(false);
        refObj.key = "group_add_edit_success";
        dispatch({type:NETWORK_ADMIN_UPDATE_GROUPS_LIST, payload:true})
      } else if (refObj && refObj.type === "error") {
        setDisabled(false);
        refObj.type = "failed";
      }
    }
  }, [uiData]);

  return (
    <EMPage title="Network Admin Group Management">
      <Box>
        <Modal
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={uiData.loading.state}
        >
          <Box
            style={{ display: "flex" }}
            alignItems="center"
            justifyContent="center"
          >
            <Box textAlign="center">
              <CircularProgress />
              <Typography style={{ fontWeight: "medium", fontSize: "large" }}>
                {uiData.loading.message}
              </Typography>
            </Box>
          </Box>
        </Modal>
        <Paper elevation={2}>
          <Box height="100%" display="flex" flexDirection="column">
            <Grid p={3}>
              {/* <Box
                width="97%"
                flexDirection="row"
                p={3}
                justifyItems="center"
                alignItems="center"
              >
                <Grid justify="flex-start" item xs={12} sm={12} md={4} lg={4}>
                  <EMEntityDropdown
                    // data={networkInfo}
                    errorText={showNetworkError}
                    onSelect={(data) => {
                      setEntity(data);
                    }}
                  />
                </Grid>
                
              </Box> */}
              <Box  style={{ paddingTop: "1%" , marginLeft:"2%"}}>
              <Grid justify="flex-start" item xs={12} sm={6} md={4}>
              <EMEntityDropdown
                    // data={networkInfo}
                    errorText={showNetworkError}
                    onSelect={(data) => {
                      setEntity(data);
                    }}
                  />
              </Grid>
            </Box>
            </Grid>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              p={3}
              justifyItems="center"
              alignItems="center"
            >
              <Grid container style={{ maxWidth: "100%" }} justify="flex-start">
                <Grid
                  width="100%"
                  justify="flex-start"
                  item
                  xs={12}
                  sm={12}
                  md={10}
                  lg={10}
                >
                  <EMDatatableSearchView
                    hint={"Search by Group Name, Email Address"}
                    onChangeListener={(data) => {
                      setFilter(data);
                    }}
                  />
                </Grid>
                <Hidden smDown>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!(permissionData && permissionData["add_flag"])}
                      onClick={() => {
                        networkSelectionHandler();
                        setShowLabels({
                          label: "Add A New Group",
                          buttonLabel: "Save",
                        });
                        dispatch({type:NETWORK_ADMIN_UPDATE_GROUPS_LIST, payload:false})
                        setDisabled(false);
                        setEditData({});
                      }}
                    >
                      {"Add New group"}
                    </Button>
                  </Grid>
                </Hidden>
                <Hidden lgUp mdUp>
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <Button
                      style={{ marginTop: "2rem" }}
                      variant="contained"
                      color="primary"
                      disabled={!(permissionData && permissionData["add_flag"])}
                      onClick={() => {
                        networkSelectionHandler();
                        setShowLabels({
                          label: "Add A New Group",
                          buttonLabel: "Save",
                        });
                        dispatch({type:NETWORK_ADMIN_UPDATE_GROUPS_LIST, payload:false})
                        setDisabled(false);
                        setEditData({});
                      }}
                    >
                      {"Add New group"}
                    </Button>
                  </Grid>
                </Hidden>
              </Grid>
              <Box>
                <EMAddEditGroup
                  open={open}
                  pagelabel={showLabels.label}
                  buttonLabel={showLabels.buttonLabel}
                  close={handleClose}
                  groupData={editData}
                  entity={entity}
                  saveClicked={saveClicked}
                  setDisabled={setDisabled}
                />
              </Box>
            </Box>
            <EMGroupTable
              filter={filter}
              entity={entity}
              permissionData={permissionData}
              onEdit={(groupData) => {
                setEditData(groupData);
                setOpen(true);
                setShowLabels({
                  label: "Edit Group Details",
                  buttonLabel: "Save",
                });
              }}
            />
          </Box>
        </Paper>
      </Box>
    </EMPage>
  );
}
