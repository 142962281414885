import { useState, useEffect } from "react";
import * as yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import { useFormik, Field } from "formik";
import API from "../../api";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 125,
    width: "100%",
  }
}));

export default function EMCountryAndRegionSelector({
  idCountry,
  idState,
  handleChangeCountry,
  handleChangeState,
  valueCountry,
  valueState,
  errorCountry,
  errorState,
  helperTextCountry,
  helperTextState,
  minWidthProp, 
  required,
  showOnlyCountry
}) {
  const [countryAndStateData, setCountryAndStateData] = useState([]);
  const classes = useStyles();

  const getCountryAndStateDataFromServer = () => {

    API.EMDoGetCountryAndRegionList()
      .then(data => data.data)
      .then(data => {
        if (data) {
          setCountryAndStateData(data);
        }
      })
      .catch(err => {
      });
  };

  useEffect(() => {
    getCountryAndStateDataFromServer();
  }, []);

  return (
    <div style={{display:"flex"}}>
      <FormControl required={required} className={classes.formControl} style={{ minWidth: minWidthProp, marginRight: "5%" }}>
        <InputLabel id="demo-simple-select-label" style={{color:"#4d4d4d"}}>Country</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id={idCountry}
          name={idCountry}
          value={valueCountry || ""}
          onChange={handleChangeCountry}
          // error={errorCountry}
          helperText={helperTextCountry}
        >
          {countryAndStateData.map(country => (
            <MenuItem
              value={country.countryName}
              key={country.countryShortCode}
            >
              {country.countryName}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText style={{ color: 'red' }}>
          {errorCountry}
          {helperTextCountry}
        </FormHelperText>
      </FormControl>
    {!showOnlyCountry && <FormControl className={classes.formControl} required={required}>
        <InputLabel id="demo-simple-select-label" style={{color:"#4d4d4d"}}>State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id={idState}
          name={idState}
          value={valueState || ""}
          onChange={handleChangeState}
          disabled={!valueCountry}
          // error={errorState}
          helperText={helperTextState}
        >
          {valueCountry
            ? countryAndStateData.length > 0 &&
            countryAndStateData
              .find(({ countryName }) => countryName === valueCountry)
              .state.map(region => (
                <MenuItem value={region.name} key={region.shortCode}>
                  {region.name}
                </MenuItem>
              ))
            : []}
        </Select>
        <FormHelperText style={{ color: 'red' }}>
          {errorState}
          {helperTextState}
        </FormHelperText>
      </FormControl>}
    </div>
  );
}
