import {
  Grid,
  Paper,
  Typography,
  Button,
  GridList,
  GridListTile,
  Box,
  CardMedia,
  ButtonBase,
  IconButton,
  Modal,
  Avatar,
  CardContent,
  useMediaQuery,
  ImageList,
  ImageListItem,
  Hidden,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import EMPage from "../../../../components/commans/EMPage";
import EMUser from "../../../../components/user/EMUser";
import { useLocation } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LinkIcon from "@material-ui/icons/Link";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import EMGroupView from "../../../../components/user/EMGroupView";
import EMSignupPage from "../../../auth/EMSignupPage";
import EMLinkProfile from "./EMLinkProfile";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  PROFILE_EDIT_TOGGLE,
  LINK_PROFILE,
} from "../../../../redux/constants/UserPlatform/EMPeopleConstant";
import user_image from "../../../../assets/user_image.png";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { find, isEmpty } from "lodash";
import {
  EMDoGetProfileDetailsAction,
  EMDoGetGroupsOfUserForProfileAction,
  EMDoGetChannelsOfUserForProfileAction,
  EMDoGetUserLevelsAndBadgesAction,
} from "../../../../redux/actions/UserPlatform/People/EMPeopleActions";
import {
  ADD_SUBTABS_TO_GROUP,
  CHANGE_ELEMENT_FOR_SUBTABS,
} from "../../../../redux/constants/UserPlatform/EMNavigationConstant";
import {
  EMDoUnLinkEmailToPrimaryAccountAction,
  EMDoGetMultipleProfileDetailsAction,
} from "../../../../redux/actions/EMAuthActions";
import {
  EMDoGetAllInformationAboutUserAction,
  EMDoGetOrganizationDepartmentsAction,
  EMDoGetOrganizationLevelsAction,
  EMDoGetOrganizationTypeAction,
} from "../../../../redux/actions/EMCommonActions";
import { findLast } from "lodash";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import profile_image from "../../../../assets/user_image.png";
import { createTheme } from "@material-ui/core/styles";
import EMSideBar from "../../../../components/navigation/EMSideBar";
import { ReactComponent as Level } from "../../../../assets/level.svg";
import { ReactComponent as ZPointsBlue } from "../../../../assets/z_points_blue.svg";
import { ReactComponent as Leaderboard } from "../../../../assets/leaderboard.svg";
import Level0 from "../../../../assets/Level0.png";
import Level1 from "../../../../assets/Level1.png";
import Level2 from "../../../../assets/Level2.png";
import Level3 from "../../../../assets/Level3.png";
import Level4 from "../../../../assets/Level4.png";
import Level5 from "../../../../assets/Level5.png";
import Level6 from "../../../../assets/Level6.png";
import Level7 from "../../../../assets/Level7.png";
import Level8 from "../../../../assets/Level8.png";
import Level9 from "../../../../assets/Level9.png";
import Level10 from "../../../../assets/Level10.png";
import super_poll_creator from "../../../../assets/super_poll_creator.png";
import super_quiz_creator from "../../../../assets/super_quiz_creator.png";
import top_platform_user from "../../../../assets/top_platform_user.png";
import super_logger from "../../../../assets/super_logger.png";
import super_group_joiner from "../../../../assets/super_group_joiner.png";
import super_channel_joiner from "../../../../assets/super_channel_joiner.png";
import super_post_creator from "../../../../assets/super_post_creator.png";
import super_commentor from "../../../../assets/super_commentor.png";
import super_liker from "../../../../assets/super_liker.png";
import super_event_creator from "../../../../assets/super_event_creator.png";
import ProgressBar from "../../../../components/user/EMProgressBar";
import EMLeaderBoard from "../../Home/LatestPost/EMLeaderBoard";

const useStyles = makeStyles((theme) => ({
  rootForGroups: {
    // marginTop: "2%",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridListforGroups: {
    width: "100%",
    height: "100%",
    padding: 10,
    // maxHeight: window.screen.height,
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  root: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginRight: "2%",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    maxWidth: window.screen.width,
    "&::-webkit-scrollbar": {
      width: "0em",
      height: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#4A4A4A",
      outline: "1px solid slategrey",
    },
  },
  gridListTile: {
    marginTop: "4px",
    marginBottom: "10px",
    borderRadius: "10px",
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);",
  },
  //User Profile Specific Styles
  gridListforOrganization: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: window.screen.width / 2.5,
    // height: "50px",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      // width: '0.5em',
      height: "0.4em",
    },
    // "&::-webkit-scrollbar-track": {
    //   boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    //   webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    // },
    // "&::-webkit-scrollbar-thumb": {
    //   backgroundColor: "#4A4A4A",
    //   outline: "1px solid slategrey",
    // },
  },
  button: {
    backgroundColor: "white",
    // margin: "2%",
  },
  titleGroupChannel: {
    padding: "16px",
  },
  editPost: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.up("xs")]: {
      width: "80%",
      marginLeft: "10%",
    },
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      marginLeft: "10%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
      marginLeft: "30%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "20%",
      marginLeft: "35%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "20%",
      marginLeft: "40%",
    },
    //
  },
  typo: {
    display: "-webkit-box",
    boxOrient: "vertical",
    wordBreak: "break-word",
    overflow: "hidden",
  },
}));

function MyProfile(props) {
  const classes = useStyles();
  const location = useLocation();
  const navigation = useNavigate();
  const data = useSelector((state) => state.myPeople);
  const profileDetails = useSelector((state) => state.myPeople.profileDetails);
  const levelAndBadges = useSelector((state) => state.myPeople.levelAndBadges);
  const profileGroups = useSelector((state) => state.myPeople.profileGroups);
  const profileChannels = useSelector(
    (state) => state.myPeople.profileChannels
  );
  const uiData = useSelector((state) => state.ui || {});
  const loginUserData = useSelector((state) => state.auth);
  const myRef = React.useRef(null);
  const channelRef = React.useRef(null);
  const badgesRef = React.useRef(null);
  const dispatch = useDispatch();
  let name;
  const [disabled, setDisabled] = useState(false);
  const [disable, setDisable] = useState(false);
  const [showLinkButton, setShowLinkButton] = useState();
  const profileId = useParams();
  const [organizationId, setOrganizationId] = useState();
  const [openLeadeboard, setOpenLeaderboard] = useState(false);

  const theme = useTheme();

  const matchesMediumScreen = useMediaQuery(theme.breakpoints.up("md"));

  const matchesLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const matchesXLScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const matchesMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const matchesSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let id = profileId.id;
    if (id != null) {
      dispatch(EMDoGetAllInformationAboutUserAction({ id: id }));
      dispatch(EMDoGetGroupsOfUserForProfileAction(id));
      dispatch(EMDoGetChannelsOfUserForProfileAction(id));
      dispatch(EMDoGetUserLevelsAndBadgesAction({ userId: id }));
    }
    dispatch({ type: ADD_SUBTABS_TO_GROUP });
  }, []);

  useEffect(() => {
    if (profileDetails) {
      if (
        profileDetails.is_linked === false ||
        profileDetails.is_primary_profile === true
      ) {
        setShowLinkButton(true);
      }

      let organizationName = profileDetails.organization;
      let organization =
        loginUserData &&
        loginUserData.multipleProfiles.find(
          (data) => data.organization_name == organizationName
        );
        console.log("organization.organization_id",organization)

      if (organization) {
       
        setOrganizationId(organization.organization_id);
      }
    }
  }, [profileDetails]);

  function getCols(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 3;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 3;
    }
    if (isWidthUp("md", screenWidth)) {
      return 2;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 2;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 1;
    }
    return 3;
  }

  function getColsForBadges(screenWidth) {
    if (isWidthUp("xl", screenWidth)) {
      return 7;
    }

    if (isWidthUp("lg", screenWidth)) {
      return 6;
    }
    if (isWidthUp("md", screenWidth)) {
      return 5;
    }
    if (isWidthUp("sm", screenWidth)) {
      return 4;
    }
    if (isWidthUp("xs", screenWidth)) {
      return 3;
    }
    return 5;
  }

  const colsForBadges = getColsForBadges(props.width);
  const cols = getCols(props.width);

  return (
    <EMPage
      title={profileDetails && profileDetails.first_name + "'s Profile"}
      style={{
        width: "100%",
        paddingLeft: matchesLargeScreen ? "8%" : "4%",
        paddingTop: matchesLargeScreen
          ? "4%"
          : matchesMediumScreen
          ? "6%"
          : "8%",
        paddingRight: matchesLargeScreen ? "8%" : "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid container spacing={4}>
        {matchesMediumScreen ? (
          <Grid item xs={matchesLargeScreen ? 2 : 3}>
            <EMSideBar />
          </Grid>
        ) : null}
        <Grid item xs={matchesLargeScreen ? 10 : matchesMediumScreen ? 9 : 12}>
          <Paper elevation={2} style={{ width: "100%" }}>
            {matchesMediumScreen ? (
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  style={{ padding: "2%", display: "flex" }}
                >
                  <Box
                    style={{
                      width: matchesXLScreen
                        ? "18%"
                        : matchesLargeScreen
                        ? "20%"
                        : "30%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        // backgroundColor: "#d3d3d3",
                      }}
                    >
                      <img
                        alt=""
                        style={{
                          flexShrink: 0,
                          minWidth: "100%",
                          minHeight: "100%",
                        }}
                        src={
                          // (profileDetails &&
                          //   profileDetails.profile_pic_file) ||
                          profileDetails &&
                          profileDetails.profile_pic_file &&
                          !profileDetails.profile_pic_file.includes("undefined")
                            ? process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                              profileDetails.profile_pic_file
                            : profile_image
                        }
                      />
                    </div>
                    <Box
                      style={{
                        backgroundColor: "#30302f",
                        marginLeft: "0.5px",
                        padding: "3%",
                        // borderRadius: "0px 0px 5px 5px",
                      }}
                    >
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={5}>
                          <img
                            src={
                              levelAndBadges && levelAndBadges.level_no == 0
                                ? Level0
                                : levelAndBadges && levelAndBadges.level_no == 1
                                ? Level1
                                : levelAndBadges && levelAndBadges.level_no == 2
                                ? Level2
                                : levelAndBadges && levelAndBadges.level_no == 3
                                ? Level3
                                : levelAndBadges && levelAndBadges.level_no == 4
                                ? Level4
                                : levelAndBadges && levelAndBadges.level_no == 5
                                ? Level5
                                : levelAndBadges && levelAndBadges.level_no == 6
                                ? Level6
                                : levelAndBadges && levelAndBadges.level_no == 7
                                ? Level7
                                : levelAndBadges && levelAndBadges.level_no == 8
                                ? Level8
                                : levelAndBadges && levelAndBadges.level_no == 9
                                ? Level9
                                : Level10
                            }
                            alt=""
                            style={{ maxWidth: "100%", maxHeight: "100%" }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Box
                            flexDirection="row"
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            {" "}
                            <Typography
                              style={{
                                color: "white",
                                fontWeight: "bolder",
                                fontSize: "medium",
                              }}
                            >
                              Rank :{" "}
                              {levelAndBadges.rank &&
                              levelAndBadges.rank == "0th"
                                ? "NA"
                                : levelAndBadges.rank && levelAndBadges.rank}
                            </Typography>
                          </Box>
                          <Box
                            flexDirection="row"
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                            mr="10px"
                          >
                            <Typography
                              style={{
                                color: "white",
                                fontWeight: "bolder",
                                fontSize: "medium",
                                marginRight: "4%",
                              }}
                            >
                              {levelAndBadges.z_points &&
                                levelAndBadges.z_points}
                            </Typography>
                            <ZPointsBlue />
                          </Box>
                          <Box
                            onClick={() => {
                              setOpenLeaderboard(true);
                            }}
                            flexDirection="row"
                            display="flex"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Leaderboard />
                            <Typography
                              style={{
                                color: "white",
                                fontSize: "8px",
                                marginLeft: "4%",
                                cursor: "pointer",
                              }}
                            >
                              View Leaderboard
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Modal
                        className={classes.editPost}
                        open={openLeadeboard}
                        onClose={() => setOpenLeaderboard(!openLeadeboard)}
                      >
                        <EMLeaderBoard fromProfile={true} />
                      </Modal>
                      <Box mt="8px">
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "10px",
                            fontWeight: "bolder",
                          }}
                        >
                          Level Progress:
                        </Typography>
                        <ProgressBar
                          bgcolor={"#1be364"}
                          label={""}
                          completed={
                            levelAndBadges &&
                            parseInt(
                              (levelAndBadges.z_points /
                                levelAndBadges.pt_to_reach_next_level) *
                                100
                            )
                          }
                        />
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            style={{
                              color: "#1be364",
                              fontSize: "10px",
                              fontWeight: "bolder",
                            }}
                          >
                            {levelAndBadges && levelAndBadges.level_difference}
                          </Typography>
                          &nbsp;
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "10px",
                              fontWeight: "bolder",
                            }}
                          >
                            to next level
                          </Typography>
                        </Box>
                      </Box>
                      <Box mt="8px">
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "10px",
                            fontWeight: "bolder",
                          }}
                        >
                          Profile title:
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            fontSize: "small",
                            textAlign: "center",
                          }}
                        >
                          {levelAndBadges && levelAndBadges.level}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      paddingLeft: "16px",
                      width: matchesXLScreen
                        ? "82%"
                        : matchesLargeScreen
                        ? "80%"
                        : "70%",
                    }}
                  >
                    {/* <Grid xs={12} container direction="row"> */}
                    <Grid container direction="row">
                      <Grid item xl={9} lg={9} md={8}>
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="h5"
                            style={{ fontWeight: "bold" }}
                            className={classes.typo}
                          >
                            {profileDetails && profileDetails.name},{" "}
                            {profileDetails && profileDetails.pronouns ? (
                              <>
                                (
                                {profileDetails.pronouns.map(
                                  (pronoun, index) => pronoun[index + 1]
                                )}
                                )
                              </>
                            ) : null}{" "}
                          </Typography>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Button>
                              <a
                                href={
                                  profileDetails &&
                                  profileDetails.linkedin_profile_link
                                }
                                rel="noreferrer"
                                target="_blank"
                                onClick="javascript.function();"
                                style={{
                                  textDecoration: "none",
                                  color: "#2F92D6",
                                }}
                              >
                                {/* <LinkedInIcon /> */}
                              </a>
                            </Button>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xl={3} lg={3} md={4}>
                        {profileDetails &&
                        loginUserData.profile_id == profileDetails.id ? (
                          <Button
                            variant="contained"
                            className={classes.button}
                            onClick={() => {
                              dispatch({
                                type: PROFILE_EDIT_TOGGLE,
                                payload: true,
                              });
                              dispatch(
                                EMDoGetOrganizationLevelsAction({
                                  orgId: organizationId,
                                })
                              );
                              dispatch(
                                EMDoGetOrganizationDepartmentsAction({
                                  orgId: organizationId,
                                })
                              );
                            }}
                            startIcon={<EditOutlinedIcon />}
                          >
                            Edit Profile
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container direction="row">
                      <Grid item xl={9} lg={9} md={8}>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "bold" }}
                          className={classes.typo}
                        >
                          {profileDetails && profileDetails.organization_name},{" "}
                          {profileDetails && profileDetails.professional_title}
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "bolder" }}
                          className={classes.typo}
                        >
                          {profileDetails && profileDetails.department}
                        </Typography>
                        <Typography
                          variant="h6"
                          style={{ fontWeight: "bolder" }}
                          className={classes.typo}
                          // style={{ marginTop: "5%" }}
                        >
                          {profileDetails && profileDetails.location}
                        </Typography>
                      </Grid>
                      <Grid item xl={3} lg={3} md={4}>
                        {profileDetails &&
                        loginUserData.profile_id == profileDetails.id ? (
                          showLinkButton === true ? (
                            <Button
                              variant="contained"
                              // disabled={disabled}
                              className={classes.button}
                              onClick={() => {
                                setDisabled(true);
                                dispatch({ type: LINK_PROFILE, payload: true });
                              }}
                              startIcon={<LinkIcon />}
                            >
                              Link Profile
                            </Button>
                          ) : (
                            <Button
                              className={classes.button}
                              variant="contained"
                              disabled={disable}
                              // color="secondary"
                              onClick={() => {
                                setDisable(true);
                                dispatch(
                                  EMDoUnLinkEmailToPrimaryAccountAction({
                                    email: profileDetails.email,
                                    id: profileDetails.id,
                                  })
                                );
                                dispatch(EMDoGetMultipleProfileDetailsAction());
                              }}
                            >
                              Un-Link Profile
                            </Button>
                          )
                        ) : null}
                      </Grid>
                    </Grid>
                    {/* </Grid> */}
                    {/* <Grid xs={12}> */}
                    <Box>
                      <Typography style={{ fontSize: "small" }}>
                        {profileDetails && profileDetails.profile_summary}
                      </Typography>
                    </Box>
                    {/* </Grid>
                <Grid xs={12}> */}
                    <Box>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        {profileDetails && profileDetails.leadership_roles
                          ? "Leadership Roles"
                          : null}
                      </Typography>
                      <Box>
                        <Typography style={{ fontSize: "small" }}>
                          {profileDetails && profileDetails.leadership_roles}
                        </Typography>
                      </Box>
                    </Box>
                    {/* </Grid> */}
                    {/* <Grid xs={12}> */}
                    <Box>
                      <Typography variant="h6" style={{ fontWeight: "bold" }}>
                        {profileDetails &&
                        profileDetails.partnerships &&
                        profileDetails.partnerships.length > 0
                          ? "Working With Partners"
                          : null}
                      </Typography>
                      <Grid style={{ marginTop: "2%" }}>
                        <div
                          className={
                            profileDetails &&
                            profileDetails.partnerships &&
                            profileDetails.partnerships.length > 8
                              ? classes.root
                              : classes.rootForGroups
                          }
                        >
                          <GridList
                            className={
                              profileDetails &&
                              profileDetails.partnerships &&
                              profileDetails.partnerships.length > 8
                                ? classes.gridList
                                : classes.gridListforGroups
                            }
                          >
                            {profileDetails &&
                              profileDetails.partnerships &&
                              profileDetails.partnerships.map((org, index) => (
                                <GridListTile
                                  key={index}
                                  style={{
                                    overflow: "hidden",
                                    width: "80px",
                                    height: "80px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Paper
                                    elevation={2}
                                    style={{
                                      width: "60px",
                                      height: "60px",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      display: "flex",
                                    }}
                                  >
                                    {org.logo_file ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          whiteSpace: "nowrap",
                                          textAlign: "center",
                                        }}
                                      >
                                        <span
                                          style={{
                                            display: "inline-block",
                                            height: "100%",
                                            verticalAlign: "middle",
                                          }}
                                        ></span>
                                        <img
                                          alt=""
                                          style={{
                                            maxWidth: "100%",
                                            maxHeight: "100%",
                                            verticalAlign: "middle",
                                          }}
                                          src={
                                            process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                              0,
                                              -1
                                            ) + org.logo_file
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                      >
                                        <Avatar
                                          style={{
                                            margin: "auto",
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          {org.partnership_name
                                            .charAt(0)
                                            .toUpperCase()}
                                        </Avatar>
                                      </Box>
                                    )}
                                  </Paper>
                                </GridListTile>
                              ))}
                          </GridList>
                        </div>
                      </Grid>
                    </Box>
                    {/* </Grid> */}
                  </Box>
                </Box>
              </>
            ) : null}
            {matchesSmallScreen ? (
              <>
                <Box padding="16px">
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={6} sm={4}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                          // backgroundColor: "#d3d3d3",
                        }}
                      >
                        <img
                          alt=""
                          style={{
                            flexShrink: 0,
                            minWidth: "100%",
                            minHeight: "100%",
                          }}
                          src={
                            // (profileDetails &&
                            //   profileDetails.profile_pic_file) ||
                            profileDetails &&
                            profileDetails.profile_pic_file &&
                            !profileDetails.profile_pic_file.includes(
                              "undefined"
                            )
                              ? process.env.REACT_APP_BACKEND_FILE_PREVIEW +
                                profileDetails.profile_pic_file
                              : profile_image
                          }
                        />
                      </div>
                      <Box
                        style={{
                          backgroundColor: "#30302f",
                          marginLeft: "0.5px",
                          padding: "3%",
                          //borderRadius: "0px 0px 5px 5px",
                        }}
                      >
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={5}>
                            <img
                              src={
                                levelAndBadges && levelAndBadges.level_no == 0
                                  ? Level0
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 1
                                  ? Level1
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 2
                                  ? Level2
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 3
                                  ? Level3
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 4
                                  ? Level4
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 5
                                  ? Level5
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 6
                                  ? Level6
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 7
                                  ? Level7
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 8
                                  ? Level8
                                  : levelAndBadges &&
                                    levelAndBadges.level_no == 9
                                  ? Level9
                                  : Level10
                              }
                              alt=""
                              style={{ maxWidth: "100%", maxHeight: "100%" }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={7}
                            container
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Box
                              flexDirection="row"
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              {" "}
                              <Typography
                                style={{
                                  color: "white",
                                  fontWeight: "bolder",
                                  fontSize: "medium",
                                }}
                              >
                                Rank :{" "}
                                {levelAndBadges.rank &&
                                levelAndBadges.rank == "0th"
                                  ? "NA"
                                  : levelAndBadges.rank && levelAndBadges.rank}
                              </Typography>
                            </Box>
                            <Box
                              flexDirection="row"
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                              mr="10px"
                            >
                              <Typography
                                style={{
                                  color: "white",
                                  fontWeight: "bolder",
                                  fontSize: "medium",
                                  marginRight: "4%",
                                }}
                              >
                                {levelAndBadges.z_points &&
                                  levelAndBadges.z_points}
                              </Typography>
                              <ZPointsBlue />
                            </Box>
                            <Box
                              onClick={() => {
                                setOpenLeaderboard(true);
                              }}
                              flexDirection="row"
                              display="flex"
                              justifyContent="flex-start"
                              alignItems="center"
                            >
                              <Leaderboard />
                              <Typography
                                style={{
                                  color: "white",
                                  fontSize: "8px",
                                  marginLeft: "4%",
                                  cursor: "pointer",
                                }}
                              >
                                View Leaderboard
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <Modal
                          className={classes.editPost}
                          open={openLeadeboard}
                          onClose={() => setOpenLeaderboard(!openLeadeboard)}
                        >
                          <EMLeaderBoard fromProfile={true} />
                        </Modal>
                        <Box mt="8px">
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "10px",
                              fontWeight: "bolder",
                            }}
                          >
                            Level Progress:
                          </Typography>
                          <ProgressBar
                            bgcolor={"#1be364"}
                            label={""}
                            completed={
                              levelAndBadges &&
                              parseInt(
                                (levelAndBadges.z_points /
                                  levelAndBadges.pt_to_reach_next_level) *
                                  100
                              )
                            }
                          />
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              style={{
                                color: "#1be364",
                                fontSize: "10px",
                                fontWeight: "bolder",
                              }}
                            >
                              {levelAndBadges &&
                                levelAndBadges.level_difference}
                            </Typography>
                            &nbsp;
                            <Typography
                              style={{
                                color: "white",
                                fontSize: "10px",
                                fontWeight: "bolder",
                              }}
                            >
                              to next level
                            </Typography>
                          </Box>
                        </Box>
                        <Box mt="8px">
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "10px",
                              fontWeight: "bolder",
                            }}
                          >
                            Profile title:
                          </Typography>
                          <Typography
                            style={{
                              color: "white",
                              fontSize: "small",
                              textAlign: "center",
                            }}
                          >
                            {levelAndBadges && levelAndBadges.level}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid xs={6} sm={4}>
                      <Grid container direction="row">
                        <Grid item xs={10}>
                          <Typography
                            variant="h5"
                            style={{ fontWeight: "bold" }}
                            className={classes.typo}
                          >
                            {profileDetails && profileDetails.name},{" "}
                            {profileDetails && profileDetails.pronouns ? (
                              <>
                                (
                                {profileDetails.pronouns.map(
                                  (pronoun, index) => pronoun[index + 1]
                                )}
                                )
                              </>
                            ) : null}{" "}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          container
                          alignItems="flex-start"
                          justifyContent="center"
                        >
                          <Button>
                            <a
                              href={
                                profileDetails &&
                                profileDetails.linkedin_profile_link
                              }
                              rel="noreferrer"
                              target="_blank"
                              onClick="javascript.function();"
                              style={{
                                textDecoration: "none",
                                color: "#2F92D6",
                              }}
                            >
                              <LinkedInIcon />
                            </a>
                          </Button>
                        </Grid>
                      </Grid>

                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bold" }}
                        className={classes.typo}
                      >
                        {profileDetails && profileDetails.organization_name},{" "}
                        {profileDetails && profileDetails.professional_title}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bolder" }}
                        className={classes.typo}
                      >
                        {profileDetails && profileDetails.department}
                      </Typography>
                      <Typography
                        variant="h6"
                        style={{ fontWeight: "bolder" }}
                        className={classes.typo}
                        // style={{ marginTop: "5%" }}
                      >
                        {profileDetails && profileDetails.location}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-evenly"
                    pt={1}
                    pb={1}
                  >
                    {profileDetails &&
                    loginUserData.profile_id == profileDetails.id ? (
                      <Button
                        variant="contained"
                        className={classes.button}
                        onClick={() => {
                          dispatch({
                            type: PROFILE_EDIT_TOGGLE,
                            payload: true,
                          });
                          dispatch(
                            EMDoGetOrganizationLevelsAction({
                              orgId: organizationId,
                            })
                          );
                          dispatch(
                            EMDoGetOrganizationDepartmentsAction({
                              orgId: organizationId,
                            })
                          );
                        }}
                        startIcon={<EditOutlinedIcon />}
                      >
                        Edit Profile
                      </Button>
                    ) : null}
                    {profileDetails &&
                    loginUserData.profile_id == profileDetails.id ? (
                      showLinkButton === true ? (
                        <Button
                          variant="contained"
                          // disabled={true}

                          // disabled={disabled}
                          className={classes.button}
                          onClick={() => {
                            setDisabled(true);
                            dispatch({ type: LINK_PROFILE, payload: true });
                          }}
                          startIcon={<LinkIcon />}
                        >
                          Link Profile
                        </Button>
                      ) : (
                        <Button
                          className={classes.button}
                          variant="contained"
                          disabled={disable}
                          // color="secondary"
                          onClick={() => {
                            setDisable(true);
                            dispatch(
                              EMDoUnLinkEmailToPrimaryAccountAction({
                                email: profileDetails.email,
                                id: profileDetails.id,
                              })
                            );
                            dispatch(EMDoGetMultipleProfileDetailsAction());
                          }}
                        >
                          Un-Link Profile
                        </Button>
                      )
                    ) : null}
                  </Box>
                  <Box pt={1} pb={1}>
                    <Typography style={{ fontSize: "small" }}>
                      {profileDetails && profileDetails.profile_summary}
                    </Typography>
                  </Box>
                  <Box pt={1} pb={1}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Leadership Roles
                    </Typography>
                    <Box>
                      <Typography style={{ fontSize: "small" }}>
                        {profileDetails && profileDetails.leadership_roles}
                      </Typography>
                    </Box>
                  </Box>
                  <Box pt={1} pb={1}>
                    <Typography variant="h6" style={{ fontWeight: "bold" }}>
                      Working With Partners
                    </Typography>
                    <Grid style={{ marginTop: "2%" }}>
                      <div
                        className={
                          profileDetails &&
                          profileDetails.partnerships &&
                          profileDetails.partnerships > 8
                            ? classes.root
                            : classes.rootForGroups
                        }
                      >
                        <GridList
                          className={
                            profileDetails &&
                            profileDetails.partnerships &&
                            profileDetails.partnerships.length > 8
                              ? classes.gridList
                              : classes.gridListforGroups
                          }
                        >
                          {profileDetails &&
                            profileDetails.partnerships &&
                            profileDetails.partnerships.map((org, index) => (
                              <GridListTile
                                key={index}
                                style={{
                                  overflow: "hidden",
                                  width: "80px",
                                  height: "80px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Paper
                                  elevation={2}
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  {org.logo_file ? (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        whiteSpace: "nowrap",
                                        textAlign: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "inline-block",
                                          height: "100%",
                                          verticalAlign: "middle",
                                        }}
                                      ></span>
                                      <img
                                        alt=""
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                          verticalAlign: "middle",
                                        }}
                                        src={
                                          process.env.REACT_APP_BACKEND_FILE_DOWNLOAD.slice(
                                            0,
                                            -1
                                          ) + org.logo_file
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <Box
                                      display="flex"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Avatar
                                        style={{
                                          margin: "auto",
                                          verticalAlign: "middle",
                                        }}
                                      >
                                        {org.partnership_name
                                          .charAt(0)
                                          .toUpperCase()}
                                      </Avatar>
                                    </Box>
                                  )}
                                </Paper>
                              </GridListTile>
                            ))}
                        </GridList>
                      </div>
                    </Grid>
                  </Box>
                </Box>
              </>
            ) : null}
            {levelAndBadges &&
            levelAndBadges.newBadges &&
            levelAndBadges.newBadges.length > 0 &&
            levelAndBadges.newBadges.some((value) => value.value == true) ? (
              <div className={classes.titleGroupChannel}>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  {profileDetails &&
                  loginUserData.profile_id == profileDetails.id
                    ? "My Badges"
                    : profileDetails && profileDetails.first_name + "'s Badges"}
                </Typography>
              </div>
            ) : null}

            <ImageList
              rowHeight={"4%"}
              cols={colsForBadges}
              style={{ paddingLeft: "16px", paddingRight: "16px" }}
            >
              {levelAndBadges &&
                levelAndBadges.newBadges &&
                levelAndBadges.newBadges
                  .filter((badge) => badge.value == true)
                  .map((badge, key) => (
                    <ImageListItem key={badge.badgeName}>
                      <div
                        style={{
                          width: "99%",
                          height: "100%",
                          whiteSpace: "nowrap",
                          textAlign: "center",
                          backgroundColor: "#d3d3d3",
                        }}
                      >
                        <span
                          style={{
                            display: "inline-block",
                            height: "100%",
                            verticalAlign: "middle",
                          }}
                        ></span>
                        <img
                          className={classes.currentMediaOfPost}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            verticalAlign: "middle",
                          }}
                          hidden={badge.value == false}
                          src={
                            badge.badgeName == "Super Logger" &&
                            badge.value == true
                              ? super_logger
                              : badge.badgeName == "Super Group Joiner" &&
                                badge.value == true
                              ? super_group_joiner
                              : badge.badgeName == "Super Channel Joiner" &&
                                badge.value == true
                              ? super_channel_joiner
                              : badge.badgeName == "Super Post Creator" &&
                                badge.value == true
                              ? super_post_creator
                              : badge.badgeName == "Super Commenter" &&
                                badge.value == true
                              ? super_commentor
                              : badge.badgeName == "Super Liker" &&
                                badge.value == true
                              ? super_liker
                              : badge.badgeName == "Super Poll Creator" &&
                                badge.value == true
                              ? super_poll_creator
                              : badge.badgeName == "Super Event Creator" &&
                                badge.value == true
                              ? super_event_creator
                              : badge.badgeName == "Super Quiz Creator" &&
                                badge.value == true
                              ? super_quiz_creator
                              : top_platform_user
                          }
                          alt={"df"}
                        />
                      </div>
                    </ImageListItem>
                  ))}
            </ImageList>

            {profileGroups && profileGroups.length > 0 ? (
              <div className={classes.titleGroupChannel}>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  {profileDetails &&
                  loginUserData.profile_id == profileDetails.id
                    ? "My Groups"
                    : profileDetails && profileDetails.first_name + "'s Groups"}
                </Typography>
              </div>
            ) : null}
            <div style={{ display: "flex" }}>
              {profileGroups && profileGroups.length > 4 ? (
                <IconButton
                  onClick={() => {
                    if (
                      myRef.current &&
                      myRef.current.scrollLeft !== myRef.current.scrollWidth
                    ) {
                      myRef.current.scrollLeft -=
                        myRef.current.scrollWidth / 10;
                    }
                  }}
                >
                  <NavigateBeforeIcon
                    fontSize="small"
                    style={{ color: "black" }}
                  />
                </IconButton>
              ) : null}
              <div
                className={
                  profileGroups && profileGroups.length > 4
                    ? classes.root
                    : classes.rootForGroups
                }
              >
                <GridList
                  className={
                    profileGroups && profileGroups.length > 4
                      ? classes.gridList
                      : classes.gridListforGroups
                  }
                  cols={cols}
                  ref={myRef}
                  style={{ overflowY: "hidden" }}
                >
                  {profileGroups &&
                    profileGroups.map((group, key) => (
                      <GridListTile
                        key={group.id}
                        style={{
                          marginLeft: "1%",
                          marginTop: "2%",
                          height: "150px",
                        }}
                        className={classes.gridListTile}
                      >
                        <EMGroupView
                          onStarClick={() => {}}
                          id={group.id}
                          route="browse_groups"
                          number_of_group_users={group.group_users_count}
                          group_name={group.group_name}
                          logo_file={group.logo_file}
                          isFav={group.is_fav}
                          created_by={group.created_by}
                          organization_name={group.organization_name}
                          // users_count={group.users_count}
                          privacy_type_name={group.privacy_type_name}
                          group={group}
                          onCardClick={() => {
                            navigation("/users/groups/activity", {
                              state: { GroupId: group.id },
                            });
                            window.sessionStorage.setItem("GroupId", group.id);
                          }}
                        />
                        {/* </div> */}
                      </GridListTile>
                    ))}
                </GridList>
              </div>
              {profileGroups && profileGroups.length > 4 ? (
                <IconButton
                  onClick={() => {
                    if (
                      myRef.current &&
                      myRef.current.scrollLeft !== myRef.current.scrollWidth
                    ) {
                      myRef.current.scrollLeft +=
                        myRef.current.scrollWidth / 10;
                    }
                  }}
                >
                  <NavigateNextIcon
                    fontSize="small"
                    style={{ color: "black" }}
                  />
                </IconButton>
              ) : null}
            </div>
            {/* </Box> */}
            {/* <Box > */}
            {profileChannels && profileChannels.length > 0 ? (
              <Grid className={classes.titleGroupChannel}>
                <Typography variant="h6" style={{ fontWeight: "bolder" }}>
                  {profileDetails &&
                  loginUserData.profile_id == profileDetails.id
                    ? "My Channels"
                    : profileDetails &&
                      profileDetails.first_name + "'s Channels"}
                </Typography>
              </Grid>
            ) : null}
            <div style={{ display: "flex", paddingBottom: "2%" }}>
              {profileChannels && profileChannels.length > 4 ? (
                <IconButton
                  onClick={() => {
                    if (
                      channelRef.current &&
                      channelRef.current.scrollLeft !==
                        channelRef.current.scrollWidth
                    ) {
                      channelRef.current.scrollLeft -=
                        channelRef.current.scrollWidth / 10;
                    }
                  }}
                >
                  <NavigateBeforeIcon
                    fontSize="small"
                    style={{ color: "black" }}
                  />
                </IconButton>
              ) : null}
              <div
                className={
                  profileChannels && profileChannels.length > 4
                    ? classes.root
                    : classes.rootForGroups
                }
              >
                <GridList
                  className={
                    profileChannels && profileChannels.length > 4
                      ? classes.gridList
                      : classes.gridListforGroups
                  }
                  cols={cols}
                  ref={channelRef}
                  style={{ overflowY: "hidden" }}
                >
                  {profileChannels &&
                    profileChannels.map((group, key) => (
                      <GridListTile
                        key={group.id}
                        style={{
                          marginLeft: "1%",
                          marginTop: "2%",
                          height: "150px",
                        }}
                        className={classes.gridListTile}
                      >
                        <EMGroupView
                          onStarClick={() => {}}
                          id={group.id}
                          route="browse_groups"
                          number_of_group_users={group.number_of_channel_users}
                          group_name={group.channel_name}
                          logo_file={group.logo_file}
                          isFav={group.is_fav}
                          created_by={group.created_by}
                          organization_name={group.organization_name}
                          // users_count={group.users_count}
                          privacy_type_name={group.privacy_type_name}
                          group={group}
                          onCardClick={() => {
                            navigation("/users/channels/activity", {
                              state: { ChannelId: group.id },
                            });
                            window.sessionStorage.setItem(
                              "ChannelId",
                              group.id
                            );
                          }}
                        />
                        {/* </div> */}
                      </GridListTile>
                    ))}
                </GridList>
              </div>

              {profileChannels && profileChannels.length > 4 ? (
                <IconButton
                  onClick={() => {
                    if (
                      channelRef.current &&
                      channelRef.current.scrollLeft !==
                        channelRef.current.scrollWidth
                    ) {
                      channelRef.current.scrollLeft +=
                        channelRef.current.scrollWidth / 10;
                    }
                  }}
                >
                  <NavigateNextIcon
                    fontSize="small"
                    style={{ color: "black" }}
                  />
                </IconButton>
              ) : null}
            </div>
            {/* </Box> */}
          </Paper>
        </Grid>
        <Modal
          open={data.showProfileEdit}
          onClose={() => {
            dispatch({ type: PROFILE_EDIT_TOGGLE, payload: false });
          }}
          style={{
            marginTop: "5%",
            marginLeft: "5%",
            marginRight: "5%",
            marginBottom: "5%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            // width: "80%",
          }}
        >
          <EMSignupPage editMode={true} />
        </Modal>
        <Modal
          open={data.showLinkProfile}
          onClose={() => {
            dispatch({ type: LINK_PROFILE, payload: false });
          }}
          // closeHandler={() => { dispatch({ type: LINK_PROFILE, payload: false }) }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <EMLinkProfile />
        </Modal>
      </Grid>
    </EMPage>
  );
}
export default withWidth()(MyProfile);
