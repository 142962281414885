import EMTabs from "../../components/navigation/EMTabs";
import React, { useEffect } from "react";
import NetworkAdminManagement from "./UserManagement/Network/ManageRolesAndPermissions";
import EMNetworkAdminManageUsers from "./UserManagement/Network/ManageUsers";
import ChannelManagement from "./channelManagement";
import GroupManagement from "./groupManagement";
import NetworkEmailTemplate from "./networkManagement/emailTemplates";
import NetworkOrganizationDetails from "./networkManagement/organizationDetails";
import { useSelector, useDispatch } from "react-redux";
import NetworkAdminDashboard from "./dashboard";
import NetworkAdminReports from "./reportsManagement";
import { EMDoGetLoggedInUserRolesAction } from "../../redux/actions/EMAuthActions";
import { RESET } from "../../redux/constants";
import NetworkDepartmentManagement from "./networkManagement/departments";
import NetworkLevelManagement from "./networkManagement/levels";
import GamificationReports from "./gamification/GamificationReports";
import LevelAndBadgesTable from "./gamification/LevelAndBadgesTable";
export function EMUserManagement() {
  const userData = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // let tabs = ["Manage Users", "Manage Roles & Permissions"];
  // let components = [<EMNetworkAdminManageUsers />, <NetworkAdminManagement />];
  useEffect(() => {
    // dispatch(EMDoGetLoggedInUserRolesAction());
    dispatch({type:RESET})
  }, []);
  let tabs = [];
  let components = [];
  if (
    userData.boolean_organization_manage_users ||
    userData.boolean_partnership_manage_users
  ) {
    tabs.push("Manage Users");
    components.push(<EMNetworkAdminManageUsers />);
  }
  if (
    userData.boolean_organization_manage_roles_and_permissions ||
    userData.boolean_partnership_manage_roles_and_permissions
  ) {
    tabs.push("Manage Roles & Permissions");
    components.push(<NetworkAdminManagement />);
  }
  return <EMTabs tabs={tabs} components={components} />;
}

export function EMChannelManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(EMDoGetLoggedInUserRolesAction());
  }, []);
  let tabs = ["Channel Details"];
  let components = [<ChannelManagement />];
  return <EMTabs tabs={tabs} components={components} />;
}

export function EMGroupManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(EMDoGetLoggedInUserRolesAction());
  }, []);
  let tabs = ["Group Details"];
  let components = [<GroupManagement />];
  return <EMTabs tabs={tabs} components={components} />;
}

export function EMOrganizationManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(EMDoGetLoggedInUserRolesAction());
  }, []);
  const userData = useSelector((state) => state.auth);
  // let tabs = ["Organization Details", "Email Templates"];
  // let components = [<NetworkOrganizationDetails />, <NetworkEmailTemplate />];
  let tabs = [];
  let components = [];
  if (
    userData.boolean_organization_manage_profile_page ||
    userData.boolean_partnership_manage_profile_page
  ) {
    tabs.push("Organization Details");
    components.push(<NetworkOrganizationDetails />);
  }
  if (
    userData.boolean_organization_manage_email_templates ||
    userData.boolean_partnership_manage_email_templates
  ) {
    tabs.push("Email Templates");
    components.push(<NetworkEmailTemplate />);
  }
  if (
     userData.boolean_organization_manage_profile_page 
  ) {
    tabs.push("Centers");
    components.push(<NetworkDepartmentManagement />);
  }
  if (
     userData.boolean_organization_manage_profile_page 
  ) {
    tabs.push("Levels");
    components.push(<NetworkLevelManagement />);
  }
  return <EMTabs tabs={tabs} components={components} />;
}

export function EMGamificationManagement() {
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(EMDoGetLoggedInUserRolesAction());
  }, []);
  const userData = useSelector((state) => state.auth);
  let tabs = [];
  let components = [];
  if (
    userData.boolean_organization_manage_profile_page
  ) {
    tabs.push("Gamification Reports");
    components.push(<GamificationReports />);
  }
  if (
    userData.boolean_organization_manage_profile_page
  ) {
    tabs.push("Levels and Badges");
    components.push(<LevelAndBadgesTable />);
  }
  return <EMTabs tabs={tabs} components={components} />;
}

export {
  NetworkAdminManagement,
  EMNetworkAdminManageUsers,
  ChannelManagement,
  GroupManagement,
  NetworkEmailTemplate,
  NetworkOrganizationDetails,
  NetworkAdminDashboard,
  NetworkAdminReports,
  GamificationReports,
  LevelAndBadgesTable
};
