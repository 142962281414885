import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    // minWidth: 125,
    // width: "45%",
  },
  typo:{
    display: "-webkit-box",
    boxOrient: "vertical",
    lineClamp: 1,
    wordBreak:"break-word",
    overflow: "hidden",
  },
}));

export default function EMSingleSelect({
  label,
  data,
  objKey,
  objSecondaryKey,
  onChange,
  errors,
  required,
  value,
  disabled
}) {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <FormControl disabled={disabled} className={classes.formControl} required={required} fullWidth>
      <InputLabel id="select-label" style={{color:"#4d4d4d"}}>{label}</InputLabel>
      <Select
        labelId="demo-checkbox-label"
        id="demo-checkbox"
        value={value}
        label={label}
        error={errors}
        // helperText={formik.touched.selectedNetwork && formik.errors.selectedNetwork}
        onChange={handleChange}
      >
        {data.map((d) => {
          let dataToShow = d[objSecondaryKey] ? d[objSecondaryKey] + " |" + d[objKey] : d[objKey];
          return (
            <MenuItem key={dataToShow} value={dataToShow}>
              <Tooltip
                title={<h2 style={{ color: "white" }}>{dataToShow}</h2>}
                placement="top"
              ><Typography variant="p" className={classes.typo}>
                { dataToShow}
                  </Typography>
              </Tooltip>
            </MenuItem>
          );
        })}
        {
          data?.length == 0 && <MenuItem >
          <Tooltip
            title={<h2 style={{ color: "white" }}>""</h2>}
            placement="top"
          ><Typography variant="p" className={classes.typo}>
           No data available
              </Typography>
          </Tooltip>
        </MenuItem>
        }
      </Select>
      <FormHelperText style={{ color: "red" }}>
        {errors ? errors : ""}
      </FormHelperText>
    </FormControl>
  );
}
